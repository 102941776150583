@import "_variables";
@import "_mixins";
@import "_animations";
.slick-initialized .slick-slide {
  height: auto;
}

.card__carousel {
  margin-bottom: $small-spacing;
  border-bottom: 1px solid $light-color-primary;
  max-width: 100%;
  @media (max-width: $phone-max) {
    border-bottom: 0;
    margin-bottom: 0;
  }
  @media (min-width: $tablet-min) {
    border-bottom: 0;
    background-color: white;
  }
  .card {
    border-bottom: 0;
    margin-bottom: $default-spacing;
    @media (max-width: $phone-max) {
      margin-bottom: $small-spacing;
      border-bottom: 1px solid #ddd;
    }
  }
  .card--square-thumbnail,
  .card--editorial {
    .card__footer {
      margin-top: 10px;
    }
  }
  .slick-arrow {
    position: absolute;
    top: 150px;
    z-index: 10;
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid $light-color-primary;
    border-radius: 50%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &.slick-prev {
      left: -15px;
      background-repeat: no-repeat;
      background-image: url("../img/arrow-left.png");
      background-position: center center;
      background-size: 12px 12px;
      @media (max-width: $phone-max) {
        top: 50px;
        top: 20vw;
        left: 5px;
      }
    }
    &.slick-next {
      right: -15px;
      background-repeat: no-repeat;
      background-image: url("../img/arrow-right.png");
      background-position: center center;
      background-size: 12px 12px;
      @media (max-width: $phone-max) {
        top: 50px;
        top: 20vw;
        right: 5px;
      }
    }
    &.slick-disabled {
      opacity: 0.1;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-dots {
    display: flex !important;
    padding: 0 10px;
    flex-wrap: nowrap;
    max-width: 100%;
    li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 90%;
      display: block;
      margin-right: $default-spacing;
      &.slick-active {
        button {
          background-color: $light-color-primary;
          background-image: linear-gradient(
            to right,
            $brand-color-main,
            $brand-color-main
          );
          background-repeat: no-repeat;
          background-size: 0 100%;
          animation: animateBackgroundSize 4s ease-in-out forwards;
          cursor: pointer;
          &:hover {
            animation-play-state: paused;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      button {
        width: 100%;
        font-size: 0;
        background-color: $light-color-primary;
        outline: none;
        border: 0;
        height: 5px;
      }
    }
  }
}
