@import "_variables";
@import "_mixins";
.adv {
    text-align: center;
    margin-bottom: $default-spacing;
    position: relative;
    .adv-disclaimer {
        font-size: 10px;
        letter-spacing: 2;
        color: $dark-color-secondary;
    }
    .adv-placeholder {
        margin: auto;
        width: 300px;
        height: 250px;
        background-color: $light-color-secondary;
        cursor: pointer;
        color: $dark-color-secondary;
        padding: $default-spacing;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .adv-size {
            font-weight: 300;
            display: block;
        }
        .adv-position {
            display: block;
        }
    }
}
