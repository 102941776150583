@import "_variables";
@import "_mixins";

$additional-articles-background: #FBFBFB;

.additional-articles {
  position: relative;
  background-color: $additional-articles-background;
  padding-top: $default-spacing;
  padding-bottom: $default-spacing;
  border-top: 1px solid #eee;
  .additional-articles__container {
    @include container();
    @include clearfix();
  }
  @media (max-width: $desktop-min) and (min-width: $phone-max) {
    width: 1024px;
  }
  @media (max-width: $phone-max) {
    max-width: 100%;
    border-top: 0;
  }
  .card {
    float: left;
    font-family: "Roboto Condensed", sans-serif;
    padding-right: 20px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      .title {
        color: $text-color-primary;
      }
    }
    &:last-child {
      padding-right: 0;
    }
    .card__text .card__subject {
      padding: 0;
      background-color: transparent;
      margin-bottom: 5px;
      @media (max-width: $phone-max) {
        margin-bottom: 4px;
      }
    }
    .card__subject {
      font-weight: 700;
      font-size: 14px;
      background-color: $additional-articles-background;
      @include addBrandColor();
      .subject {
        font-size: 12px;
      }
      @media (max-width: $phone-max) {
        font-size: 12px;
      }
    }
    .card__text {
      margin: 0;
      .title,
      .title__link {
        font-weight: 700;
        color: $text-color-secondary;
        font-size: 16px;
        line-height: 1.3em;
        @media (max-width: $phone-max) {
          font-size: 13px;
        }
      }
    }
  }
  /* layout */
  .card:first-child:nth-last-child(1) {
    width: 100%;
  }
  /* two items */
  .card:first-child:nth-last-child(2),
  .card:first-child:nth-last-child(2)~.card {
    width: 50%;
  }
  /* three items */
  .card:first-child:nth-last-child(3),
  .card:first-child:nth-last-child(3)~.card {
    width: 33.3333%;
    @media (max-width: $phone-max) {
      width: 100%;
    }
  }
  /* four items */
  .card:first-child:nth-last-child(4),
  .card:first-child:nth-last-child(4)~.card {
    width: 25%;
    @media (max-width: $phone-max) {
      width: 50%;
    }
  }
  /* five items */
  .card:first-child:nth-last-child(5),
  .card:first-child:nth-last-child(5)~.card {
    width: 20%;
    @media (max-width: $phone-max) {
      width: 100%;
    }
  }
  /* six items */
  .card:first-child:nth-last-child(6),
  .card:first-child:nth-last-child(6)~.card {
    width: 16.6666667%;
    @media (max-width: $phone-max) {
      width: 50%;
    }
  }
}
