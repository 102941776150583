@import "_variables";
@import "_mixins";

$menu-height: 50px;

.edition-menu {
  .qn & {
    border-top: none;
  }

  &.edition-menu--show-search-bar {
    .menu-section {
      display: none;
    }
    .edition-menu-inner {
      height: 80px;
    }
    .menu-section--search-input {
      display: block;
      padding-top: 15px;
      height: 80px;
      @include clearfix();
      position: relative
    }
  }
  .menu-item {
    &.active {
      a {
        @include addBrandColor;
      }
    }
    .menu-item__link {
      font-weight: 700;
      display: block;
      color: black;
      line-height: $menu-height;
    }
  }
}

.edition-menu, .trends-menu {
  border-top: 1px solid $light-color-primary;
  border-bottom: 1px solid $light-color-primary;
  user-select: none;
  transition: background-color .3s ease-in-out;
  @include clearfix();
  @include hideMobile();
  @media (max-width: $desktop-min) and (min-width: $tablet-min) {
    width: 1024px;
  }
}

.edition-menu-inner, .trends-menu-inner {
  @include clearfix();
  @include container(white);
  width: 1022px;
  transition: background-color .3s ease-in-out;
  position: relative;
}

.menu-sections {
  display: block;
  @include clearfix();
}

.menu-section {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0;

  &.menu-section--main {
    float: left;
  }

  &.menu-section--trends {
    float: left;
    max-width: 30%;
    white-space: nowrap;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.menu-section--trends, &.menu-section--trendingtopics {
    .menu-item {
      .menu-item__link {
        display: block;
        color: black;
        line-height: $menu-height;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  &.menu-section--trendingtopics {
    width: 100%;
    text-align: center;
  }

  &.menu-section--side {
    float: right;
    margin-left: 20px;
    ul.dropdown--grid {
      width: 300px;
      margin-bottom: 0;
    }
  }
  &.menu-section--search {
    float: right;
    height: $menu-height;
    width: 30px;
    position: relative;
    .menu-item {
      display: inline-block;
      width: 30px;
      height: $menu-height;
      padding: 0;
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      margin-right: 0;
    }
    .menu-item__icon {
      height: $menu-height;
      display: block;
      text-align: center;
      .svg {
        height: $menu-height;
        width: 30px;
        padding: 15px 0;
        opacity: .4;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }
      &:hover {
        .svg {
          transform: scale(1.1);
          opacity: 1;
        }
      }
    }
  }
  &.menu-section--search-input {
    width: 100%;
    display: none;
    height: 50px;
    background-color: transparent;
    font-size: 24px;
    .menu-item {
      margin-right: 0;
    }
    input {
      height: $menu-height - 20;
      width: 80%;
      margin-top: $small-spacing;
      margin-bottom: $small-spacing;
      border: 0;
      display: inline-block;
      background-color: transparent;
      vertical-align: middle;
      &:active,
      &:focus {
        outline: none;
      }
    }
    button {
      vertical-align: middle;
      margin-right: 20px;
      height: 50px;
      float: left;
      border: 0;
      outline: none;
      background: none;
      .svg {
        height: 50px;
        width: 50px;
        padding: 15px;
        opacity: .4;
      }
    }
    .menu-item__icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: $default-spacing;
      height: $menu-height;
      .svg {
        height: 50px;
        width: 50px;
        padding: 15px;
        transition: transform 0.3s ease-in-out;
      }
      &.menu-item__icon--close {
        float: right;
        width: 30px;
        height: 50px;
        padding-left: $small-spacing;
        padding-right: $small-spacing;
        margin-right: 0;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;
        span {
            display: none;
        }
        &:after {
            content: "\2715";
            width: 22px;
            height: $menu-height;
            line-height: $menu-height;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            position: absolute;
            top: 0;
            right: 0;
            display: inline;
            transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
        &:hover {
          &:after {
              transform: scale(1.25);
              @include addBrandColor();
          }
        }
      }
    }
  }
}

.menu-item {
  display: inline-block;
  margin-right: 13px;
  position: relative;

  .qn & {
    margin-right: 6px;
  }

  &.menu-item--dropdown {
    color: black;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      @include addBrandColor();
    }
    &.menu-item--dropdown--open {
      .menu-item__icon {
        transform: translateY(-2px) rotate(180deg);
      }
      .dropdown {
        display: block;
      }
      &.menu-item--dropdown--fill {
        padding-left: 20px;
        @include addBrandBackgroundColor;
        color: white;
        .span.menu-item__link {
          color: white;
        }
      }
    }
    .menu-item__icon {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
      width: 10px;
      height: 8px;
      transition: transform .3s ease-in-out;
      line-height: $menu-height;
      display: block;
      position: relative;
      &:before {
        @include addBrandColor();
        content: "\25E2";
        font-style: normal;
        line-height: 8px;
        font-size: 8px;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-4px) rotate(45deg);
      }
      .svg {
        display: block;
        width: 100%;
        height: auto;
        .styleme {
          @include addBrandFillColor();
        }
      }
    }
  }
  .menu-item__link {
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s ease-in-out;
    &:hover {
      @include addBrandColor();
    }
    .menu-item__icon {
      line-height: $menu-height;
      display: inline-block;
    }
  }
}
