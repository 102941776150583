@import "_variables";
.card--editoriale {
  .card__image.card__image-editoriale {}
  .card__subject {
    @media (max-width: $phone-max){
      padding-left: 0;
    }
  }
  .card__footer {
    float: left;
    clear: none;
    .signature {
      width: auto;
      .signature__prefix {
        font-size: 100%;
        color: $text-color-secondary;
      }
      .signature__author {
        font-weight: 400;
        text-transform: none;
        color: $text-color-secondary;
      }
    }
  }
}
