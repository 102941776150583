@import "_variables";
@import "_mixins";
.related__articles {
  //border-top: 1px solid #ddd;
  margin: $small-spacing 0;
  font-size: 16px;
  clear: both;
  display: flex;
  flex-wrap: wrap;
  .related {
    font-size: 16px;
    margin-right: $default-spacing;
    margin-left: 0;
    margin-bottom: 5px;
    text-transform: none;
    font-variant: unset;
    font-family: "Roboto slab", serif;
    @media (max-width: $phone-max) {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px dashed #ddd;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
  .related__label {
    font-weight: 700;
    @include addBrandColor();
    margin-right: 5px;
    &:after {
        content: " /"
    }
  }
  .related__text {
    color: $text-color-secondary;
    @media (max-width: $phone-max) {
      line-height: normal;
    }
  }
}
