@import "_variables";
@import "_mixins";
.card.card--gallery,
.card__image--gallery {
  @include clearfix();
  position: relative;
  display: block;
  &.card--vertical {
    .image__container {
      width: 25%;
      height: 280px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        max-width: none;
      }
    }
  }
  .image__container {
    float: left;
    width: 50%;
    height: 141px;
    overflow: hidden;
    position: relative;
    border: 1px solid white;
    font-family: $font-family-body-qn;
    font-weight: 300;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      transition: transform .3s ease-in-out;
      height: auto;
      width: auto;
      max-width: none;
      &:hover {
        transform: translate(-50%, -50%);
      }
    }
  }
}
