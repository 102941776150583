@import "_variables";
@import "_mixins";
@import "_animations";
$network-menu-height: 40px;
$badge-size: 16px;
.network-menu {
  border-bottom: 1px solid $light-color-primary;
  border-top: 1px solid $light-color-secondary;
  box-shadow: 0px 1px 2px $light-color-primary;
  @include clearfix();
  @include hideMobile();
  position: relative;
  z-index: 10000;
  @media (max-width: $desktop-min) and (min-width: $tablet-min) {
    width: 1024px;
  }
  &.network-menu--modal {
    background-color: white;
  }
  .network-menu__inner {
    @include clearfix();
    @include container(white);
    position: relative;
  }
  .network-menu__links {
    float: right;
    margin: 0;
    color: $text-color-primary;
    a {
      color: $text-color-primary;
    }
    .menu-item .menu-item__link {
      font-size: 16px;
    }
  }
  &.network-menu--modal {
  }
  .menu-item {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 0;
    user-select: none;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      > a {
        @include addBrandColor();
        text-decoration: none;
      }
    }
    &.menu-item--dropdown {
      line-height: 40px;
      &.menu-item--dropdown--open {
      }
      .dropdown {
        top: 40px;
        border-top: 2px solid $brand-color-main;
        @include addBrandBorderColor();
        .dropdown__item {
          a {
            text-transform: uppercase;
            line-height: normal;
          }
        }
      }
    }
    &.menu-item--evident {
      a {
        font-weight: 700;
        @include addBrandColor;
      }
    }
    &.menu-item--iniziativa-speciale {
      svg {
        height: 25px;
        vertical-align: text-bottom;
      }
    }
    a {
      line-height: $network-menu-height;
      display: block;
      transition: color 0.3s ease-in-out;
      color: black;
    }
  }
}

.network-text {
  line-height: 40px;
  transition: color 0.3s ease-in-out;
  color: black;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
}

.network-logo {
  padding-top: 5px;
  padding-bottom: 4px;
  float: left;
  user-select: none;
  &:hover {
    text-decoration: none;
    .network-logo__text {
      text-decoration: none;
    }
  }
  &.network-logo--inline {
    &:hover {
      .network-logo__text {
      }
      .network-logo__image {
        .svg {
        }
      }
    }
    .network-logo__text {
      font-size: 18px; //height: 30px;
      float: right;
      line-height: normal;
      color: $dark-color-secondary;
      margin-left: 5px;
      margin-top: 4px;
      font-weight: 300;
    }
    .network-logo__image {
      display: inline-block;
      height: 24px;
      vertical-align: middle;
      .svg {
        height: 24px;
      }
    }
  }
  .network-logo__text {
    font-size: 12px;
    margin-top: -3px;
    margin-bottom: -5px;
    color: black;
    transition: color 0.2s ease-in-out;
    user-select: none;
    &:hover {
      color: $text-color-primary;
    }
  }
  .network-logo__image {
    height: 20px;
    margin-right:15px;
    .svg {
      height: 20px;
    }
  }
}

.network-menu__hamburger {
  float: left;
  @include clearfix();
  cursor: pointer;
  .hamburger {
    margin-left: -10px;
    @media (max-width: $phone-max) {
      margin-top: 3px;
      margin-left: 10px;
    }
  }
}

.network-menu-close {
  float: left;
  line-height: $network-menu-height;
  user-select: none;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: 3px;
  right: -($badge-size / 2);
  display: block;
  width: $badge-size;
  height: $badge-size;
  @include addBrandBackgroundColor();
  text-align: center;
  border-radius: 50%;
  animation: pulse 1s ease-in-out infinite;
  .badge--number {
    font-size: 11px;
    color: white;
    line-height: ($badge-size + 1);
  }
}
