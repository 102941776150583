@import "_variables";
@import "_mixins";

.detail-page {
  @include clearfix();
  &.detail-page--article {
    .detail-image {
      margin-bottom: $default-spacing;
    }
  }
  .main-content {
    @include clearfix();
    &.main-content--single-column {
      flex-wrap: wrap;
      .detail-text {
        width: 600px;
        margin: auto;
        max-width: 100%;
        min-height: 250px;
      }
    }
  }

  > .wrapper {
    padding-bottom: 0;
    @media (max-width: $phone-max) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .detail-heading {
    padding-right: 15px;
    padding-left: 15px;
  }

  .detail-author {
    margin-bottom: 6px;
  }

  .main-column {
    margin-right: $default-spacing;
    float: left;
    width: 660px;
    max-width: 100%;

    @media (max-width: $phone-max) {
      float: none;
      margin: auto;
    }
    .detail-image {
      position: relative;
      overflow: hidden;
      @media (max-width: $phone-max) {
        width: 100%;
      }
      picture {
        display: inline-block;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: black;
        img {
          display: block;
          margin: auto;
          position: relative;
          z-index: 1;
        }
      }
      .image__background {
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        filter: blur(10px) grayscale(0.5);
        z-index: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.4;
      }
    }
    .detail-text {
      line-height: 1.6em;
      font-family: $font-family-body-qn;
      font-size: 20px;
      font-weight: 300;
      word-break: break-word;

      @media (max-width: $phone-max) {
        line-height: 1.7em;
        color: #212121;
      }
      /* basic markup */
      h2 {
        @media (max-width: $phone-max) {
          font-size: 20px;
          color: black;
        }
      }
      h3 {
        @media (max-width: $phone-max) {
          font-size: 18px;
          color: black;
        }
      }
      > h2 {
        @media (max-width: $phone-max) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      p {
        line-height: 1.8em;
        margin-bottom: 2em;
        @media (max-width: $phone-max) {
          line-height: 1.5em;
        }
        a {
          font-weight: 700;
          text-decoration: underline;
          /*@include underline();*/
        }
        &:nth-last-child(2) {
          /* last child is copyright notice */
          margin-bottom: 0;
          margin-block-end: 0;
        }
      }
      img {
        margin: auto;
        max-width: 100%;
        &[style*="float: right"],
        &[style*="float:right"] {
          margin-left: 10px;
        }
        &[style*="float: left"],
        &[style*="float:left"] {
          margin-right: 10px;
        }
      }
      mark {
        display: block;
        background-color: transparent;
        padding: 20px;
        font-size: 24px;
        font-family: "Roboto slab";
        font-weight: 400;
        text-align: center;
        margin: auto;
        max-width: 60%;
        line-height: 1.6em;
        color: $text-color-secondary;
        @media (max-width: $phone-max) {
          font-size: 18px;
          max-width: 100%;
        }
        &:before {
          content: "";
          display: block;
          border: 1px solid $brand-color-main;
          width: 40%;
          margin: 0 auto 20px;
        }
        &:after {
          content: "";
          display: block;
          border: 1px solid $brand-color-main;
          width: 30%;
          margin: 20px auto 0;
        }
      }
      iframe {
        max-width: 100%;
      }
    }
    .copyright-notice {
      color: $text-color-secondary;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2em;
      margin-bottom: $large-spacing;
      clear: both;
    }
  }
  /* embed inside article */

  /* twitter */
  .twitter-tweet {
    margin: $large-spacing auto !important;
  }
  /* facebook */
  iframe[src*="facebook"] {
    display: block;
    margin: $default-spacing auto;
  }
  /* youtube */
  iframe[src*="youtube"] {
    width: 100% !important;
  }

  /* video mediaset */
  iframe[src*="video.mediaset"] {
    min-height: 380px;
    width: 100% !important;
    @media (max-width: $phone-max) {
      min-height: none;
    }
  }
  /* maps */
  iframe[src*="google.com/maps/"] {
    width: 100%;
  }
  /* ISSUU */
  .issuuembed {
    margin: $large-spacing auto;
  }
  /* instagram */
  .instagram-media.instagram-media-rendered {
    margin: auto !important;
  }

  /* video */
  #viralize-player-,
  .video-js {
    margin-bottom: $default-spacing;
  }
  /* video placeholder, when the video jumps to the bottom corner of the screen */
  /* the id is randomnly generated but it always end with -placeholder so this selector matches that rule */
  .detail-image [id*="-placeholder"] {
    background-color: #eee;
  }
  /* gallery infra ads */
  .gallery-images .adv-slot-container {
    @media (max-width: $phone-max) {
      margin-bottom: 10px;
    }
  }
  /* gallery infra amp ads wrapper */
  .gallery-amp-ad {
      text-align: center;
  }
}

.main-column .detail-text mark:before,
.main-column .detail-text mark:after {
  @include addBrandBorderColor();
}
body.detail-page {
  .network-menu {
    box-shadow: none;
  }
}

/* figcaptions */

.image-with-caption {
  figcaption {
    font-family: "Roboto condensed", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.35em;
    color: $text-color-secondary;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  img {
    width: 100%;
  }
  img[style*="float"] + figcaption {
    display: none;
  }
  img[style*="float: right"],
  img[style*="float:right"] {
    margin-left: 10px !important;
  }
  img[style*="float: left"],
  img[style*="float:left"] {
    margin-right: 10px !important;
  }
}

/* firme */

.author {
  .author-img {
    display: block;
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 90px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    float: left;
  }
  &.campagna {
    .author-img {
      background-image: url(../img/logo_campagna.png);
    }
    .author-name {
      color: #c59d5f;
    }
  }
  &.cesarine {
    .author-img {
      background-image: url(../img/logo_cesarine.png);
    }
    .author-name {
      color: #e3482e;
    }
  }
  &.peronaci {
    .author-img {
      background-image: url(../img/logo_peronaci.png);
    }
    .author-name {
      color: #50b2b1;
    }
  }
  &.savings-united {
    .author-img {
      background-color: #efefef;
      background-image: url(../img/savings_united.png);
      background-size: 80%;
      border: 1px solid #ddd;
    }
    .author-name {
      color: #e44b2c;
    }
  }
  &.dettagli-home-decor {
    .author-img {
      background-color: #ccc;
      background-image: url(../img/logo_dhd.png);
    }
    .author-name {
      color: #738829;
    }
  }
  &.houzz {
    .author-img {
      background-color: #ccc;
      background-image: url(../img/logo_houzz.png);
    }
    .author-name {
      color: #7ac144;
    }
  }
  &.gourmant {
    .author-img {
      background-image: url(../img/logo_gourmant.png);
    }
    .author-name {
      color: #474035;
    }
  }
  &.speed {
    .author-img {
      background-image: url(../img/logo_speed.png);
      float: right !important;
    }
    .author-info {
      float: right !important;
      .author-role {
        display: block;
        padding-top: 20px;
      }
    }
  }
  &.astroparade {
    .author-img {
      background-image: url("../img/logo_astroparade.png");
    }
    .author-name {
      color: #0053a0;
    }
  }
  &.so24 {
    .author-img {
        background-image: url("../img/so24.png");
    }
  }
  .author-info {
    display: block;
    position: relative;
    float: left;
    margin: 15px 0 0 15px;
  }
  &.author-header {
    margin-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #d6d6d6;
    display: block;
    @include clearfix();
    .author-role {
      text-transform: uppercase;
      font-style: normal;
      letter-spacing: 2px;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      color: #777;
    }
  }
}

a.author-name,
span.author-name-2 {
  color: #323232;
  display: block;
  position: relative;
  font-family: Lato, Helvetica, Arial, sans-serif;
}

.author.author-header .author-name {
  font-weight: 700;
  line-height: 20px;
}

a.author-name {
  font-size: 20px;
  font-weight: 900;
  text-decoration: none;
  line-height: 120%;
  &:hover {
    text-decoration: underline;
  }
}

span.author-name-2 {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  text-decoration: none;
  line-height: 120%;
  padding-bottom: 5px;
  &:hover {
    text-decoration: underline;
  }
}

.author-role {
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-style: italic;
}

.riproduzione-riservata {
  clear: left;
  margin: 0 15px;
  font-weight: 200;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-style: italic;
  text-transform: uppercase;
}

.article-related .card,
.article-related .card.card-big,
.article-related .card.card-medium,
.article-related .card.card-small {
  height: 210px;
  width: 195px;
  background-color: #fff;
  box-shadow: none;
  float: left;
  margin: 0.76923%;
  padding-top: 0;
}

.article-related .card .article-img-container,
.article-related .card.card-big .article-img-container,
.article-related .card.card-medium .article-img-container,
.article-related .card.card-small .article-img-container {
  margin: 0;
}

// custom colors in the detail page

.detail-subject {
  @include addBrandBorderColor();
}

.main-column .detail-text mark  {
  &:before,
  &:after {
    @include addBrandBorderColor();
  }
}
