@import "_variables";
@import "_mixins";
.newsletter {
  margin-top: $large-spacing;
  margin-bottom: $large-spacing;
  border: 1px solid #ddd;
  padding: $large-spacing $default-spacing;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  @include clearfix();

  &.newsletter--logged {
    display: none;
    text-align: center;
    border-top: 5px solid $brand-color-main;
    @include addBrandBorderColor();
    border-left-color: #ddd !important;
    border-bottom-color: #ddd !important;
    border-right-color: #ddd !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    &:before {
      content: "";
      background-image: url("../img/svg/mail.svg");
      background-position: center center;
      background-repeat: no-repeat;
      @include addBrandBackgroundColor();
      width: 80px;
      height: 80px;
      display: block;
      border: 10px solid white;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }

    .newsletter__title {
      @include addBrandColor();
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.2em;
      margin-top: $small-spacing;
      margin-bottom: $small-spacing;
    }

    .newsletter__subtitle {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.4em;
      text-transform: none;
      margin-bottom: $default-spacing;
    }

    .newsletter__button {
      float: none;
      margin: auto;
      position: relative;
      width: 350px;
      max-width: 100%;

      .newsletter__button-icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        border: 4px solid white;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        background-image: url("../img/svg/user-profile.svg");
        transform: translate(10px, -50%);
      }

      .btn {
        width: 100%;
        color: white;
        @include addBrandBackgroundColor();
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        padding: 20px 0;
        text-transform: uppercase;
        border-radius: 30px;
        transition: background-color 0.3s ease-in-out;
        cursor: pointer;

        // &:after {
        //   content: "";
        //   -webkit-border-radius: 30px;
        //   border-radius: 30px;
        //   @include addBrandBackgroundColor();
        //   width: 120%;
        //   height: 30px;
        //   position: absolute;
        //   display: block;
        //   z-index: 0;
        //   filter: blur(20px);
        //   opacity: 0.3;
        //   left: 50%;
        //   bottom: 0;
        //   transform: translate(-50%, 50%);
        // }

        &:hover,
        &:active,
        &:focus,
        &:hover:after {
          @include addDarkBrandBackgroundColor();
        }

        &:disabled {
          background-color: #999 !important;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .newsletter__logo {
    width: 150px;
    margin-bottom: 10px;
    .svg {
      max-width: 100%;
      max-height: 30px;
    }
  }
  .newsletter__title {
    @include addBrandColor();
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2em;
    margin-bottom: $small-spacing;
  }
  .newsletter__subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4em;
    text-transform: uppercase;
    margin-bottom: $default-spacing;
  }
  .newsletter__input {
    float: left;
    width: 70%;
    padding-right: $default-spacing;
    @media (max-width: $phone-max) {
      padding-right: 0;
      float: none;
      width: 100%;
      margin-bottom: $small-spacing;
    }
    input {
      width: 100%;
      color: $text-color-secondary;
      font-size: 16px;
      line-height: 1.2em;
      height: 40px;
      padding-left: $small-spacing;
      border: 1px solid $light-color-primary;
    }
  }
  .newsletter__button {
    float: left;
    width: 30%;
    @media (max-width: $phone-max) {
      float: none;
      width: 100%;
    }
  }
}
