@import "_variables";
@mixin clearfix {
  &:after {
    content: "";
    clear: both;
    display: block;
  }
}

@mixin container($bgColor: transparent) {
  width: $container-size;
  padding-left: 22px;
  padding-right: 22px;
  margin: auto;
  background-color: $bgColor;
  @media (max-width: $phone-max) {
    max-width: 100%;
    padding: 0;
  }
}

// branding

@mixin addBrandColor() {
  color: $ilresto-color-main;
  body.qn & {
    color: $brand-color-main;
  }
  body.iltelegrafo & {
    color: $iltelegrafo-color-main;
  }
}

@mixin addBrandColorDark() {
  color: $ilresto-color-dark;
  body.qn & {
    color: $brand-color-dark;
  }
  body.iltelegrafo & {
    color: $iltelegrafo-color-dark;
  }
}

@mixin addBrandBackgroundColor() {
  background-color: $ilresto-color-main;
  body.qn & {
    background-color: $brand-color-main;
  }
  body.iltelegrafo & {
    background-color: $iltelegrafo-color-main;
  }
}

@mixin addBrandBackgroundColorTransparent() {
  background-color: $ilresto-color-transparent;
  body.qn & {
    background-color: $brand-color-transparent;
  }
  body.iltelegrafo & {
    background-color: $iltelegrafo-color-transparent;
  }
}

@mixin addDarkBrandBackgroundColor() {
  background-color: darken($ilresto-color-main, 10%);
  body.qn & {
    background-color: darken($brand-color-main, 10%);
  }
  body.iltelegrafo & {
    background-color: darken($iltelegrafo-color-main, 10%);
  }
}

@mixin addBrandBorderColor() {
  border-color: $ilresto-color-main;
  body.qn & {
    border-color: $brand-color-main;
  }
  body.iltelegrafo & {
    border-color: $iltelegrafo-color-main;
  }
}

@mixin addBrandFillColor() {
  fill: $ilresto-color-main;
  body.qn & {
    fill: $brand-color-main;
  }
  body.iltelegrafo & {
    fill: $iltelegrafo-color-main;
  }
}

@mixin addBrandStrokeColor() {
  stroke: $ilresto-color-main;
  body.qn & {
    stroke: $brand-color-main;
  }
  body.iltelegrafo & {
    stroke: $iltelegrafo-color-main;
  }
}

@mixin hideMobile {
  @media (max-width: $phone-max) {
    display: none;
  }
}

@mixin hideDesktop {
  @media (min-width: $tablet-min) {
    display: none;
  }
}

// common
@mixin clearUl {
  padding-left: 0;
  margin-bottom: 0;
}

@mixin clearLi {
  list-style: none;
  padding-left: 0;
}

@mixin limitTextLines($amount, $lineHeight) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $amount;
  overflow: hidden;
  line-height: $lineHeight;
  max-height: #{$lineHeight * $amount}em;
}

@mixin underline() {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  box-shadow: inset 0 -2px 0 $brand-color-main;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  display: inline;
  &:hover {
    box-shadow: inset 0 -50px 0 $ilresto-color-main;
    color: white;
    text-decoration: none;
    * {
      color: white;
    }
  }
  body.qn & {
    box-shadow: inset 0 -2px 0 $brand-color-main;
    &:hover {
      box-shadow: inset 0 -50px 0 $brand-color-main;
    }
  }
  body.iltelegrafo & {
    box-shadow: inset 0 -2px 0 $iltelegrafo-color-main;
    &:hover {
      box-shadow: inset 0 -50px 0 $iltelegrafo-color-main;
    }
  }
}

@mixin underlineTitle($color) {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  box-shadow: inset 0 -0.5em 0 $color;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  display: inline;
  
  body.ilresto & {
    box-shadow: inset 0 -2px 0 $ilresto-color-main;
  }
  body.lanazione & {
    box-shadow: inset 0 -2px 0 $ilresto-color-main;
  }
  body.ilgiorno & {
    box-shadow: inset 0 -2px 0 $ilresto-color-main;
  }
  body.iltelegrafo & {
    box-shadow: inset 0 -2px 0 $iltelegrafo-color-main;
  }
}

// skin mixins

@mixin containerBorderForSkin() {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
@mixin containerForSkin() {
  width: 1024px;
  margin: auto;
}

@mixin divideLayoutByNumberOfSiblings($max) {
  &:first-child:nth-last-child(1) {
    width: 100%;
  }
  $i: 2;
  @while $i < $max and $i {
    &:first-child:nth-last-child(#{$i}),
    &:first-child:nth-last-child(#{$i}) ~ & {
      width: #{100% / $i};
    }
    $i: $i + 1;
  }
}

@mixin dropCap() {
  &:first-of-type:first-letter {
    float: left;
    font-size: 5em;
    line-height: 1em;
    padding-top: 0em;
    padding-right: 0.2em;
    font-weight: 700;
  }
}

@mixin divideLayoutByNumberOfSiblingsMobile($max) {
  $i: 2;
  @while $i < $max {
    &:first-child:nth-last-child(#{$i}),
    &:first-child:nth-last-child(#{$i}) ~ & {
      @media (max-width: 768px) {
        @if ($i % 2 == 0) {
          width: 50%;
        } @else {
          width: 100%;
        }
      }
      $i: $i + 1;
    }
  }
}
