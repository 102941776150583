@import "_variables";
@import "_mixins";
$mobile-logo-height: 50px;
.mobile-navigation-container {
  display: none;
}
.mobile-menu {
  // box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  // border-bottom: 1px solid $light-color-secondary;
  @include hideDesktop();
  background-color: white;
  &.mobile-menu--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    z-index: 10000000001;
  }
  .mobile-menu__inner {
    position: relative;
    @include clearfix();
    border-bottom: 1px solid $light-color-primary;
    height: $mobile-logo-height;
  }
  .mobile-menu__logo {
    height: 50px;
    display: block;
    .svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: $mobile-logo-height;
      max-height: 20px;
      margin: auto;
      height: $mobile-logo-height;
      // max-width fallback
      max-width: 60%;
      max-width: calc(100% - 120px);
      body.ilresto & {
        max-height: 24px;
      }
    }
  }
  .mobile-menu__login {
    height: 40px;
    position: absolute;
    top: 5px;
    right: 10px;
    .user-profile {
      .user-info {
        display: none;
      }
    }
    .svg {
      height: 30px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      padding: 2px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      .styleme {
        @include addBrandFillColor();
      }
    }
  }
  .mobile-menu__iniziativa-speciale {
    height: 40px;
    position: absolute;
    top: 12px;
    right: 10px;
    svg {
      height: 25px;
    }
  }
  .mobile-menu__inner--login {
	  .mobile-menu__iniziativa-speciale {
		  right: 48px;
	  }
  }

  .submenu {
    $submenu-bg-color: #fff;
    position: relative;
    background-color: $submenu-bg-color;
    border-bottom: 1px solid #ddd;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50px;
      background-image: linear-gradient(to right, rgba($submenu-bg-color, 0), rgba($submenu-bg-color, 1));
      pointer-events: none;
      content: '';
    }

    .submenu__inner {
      display: flex;
      flex-wrap: nowrap;
      flex-shrink: 0;
      width: 100%;
      @include clearfix();
    }
    display: block;
    height: 40px;
    .menu-section {
      float: left;
      @supports (display: -webkit-flex) or (display: -moz-flex) or
        (display: flex) {
        float: none;
      }
      &.menu-section--slider {
        padding-right: 35px;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
      }
      & + .menu-section--slider {
        max-width: 60%;
        @supports (display: -webkit-flex) or (display: -moz-flex) or
          (display: flex) {
          max-width: unset;
        }
      }
      .menu-item {
        position: static;
        padding-left: $small-spacing;
        text-align: center;
        &.menu-item--city {
          float: left;
          .menu-item__link {
            background-color: white;
            padding: 4px 10px;
            border-radius: 10px;
            color: black;
            font-weight: 700;
            display: inline-block;
            line-height: normal;
            margin-top: 6px;
            white-space: nowrap;
            &:hover {
              color: black;
            }
            .svg {
              margin-left: 5px;
              width: 10px;
              .styleme {
                @include addBrandFillColor();
              }
            }
          }
        }
        .menu-item__link {
          color: #4b4b4b;
          font-size: 16px;
          line-height: 40px;
          text-align: center;
          position: relative;
          text-transform: uppercase;
          white-space: nowrap;
          &:hover {
            color: #4b4b4b;
          }
        }
      }
    }
  }
}
