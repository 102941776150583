@import "_variables";
@import "_mixins";
.discount-block {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  @include clearfix();
  .discount-item {
    display: inline-block;
    float: left;
    margin: 0 3% 20px 0;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    color: #575757;
    font-size: 14px;
    line-height: 1.5em;
    display: inline-block;

    @media (max-width: $phone-max) {
      font-size: 12px;
    }

    @media (max-width: $phone-max) {
      padding: 5px 10px;
      margin: 0 5px 10px 0;
    }

    a {
      color: black;
      font-size: 16px;
      font-weight: 700;
      transition: color .2s ease-in-out;
      text-decoration: none;
      &:hover {
        @include addBrandColor();
      }
    }
    /* layout */
    &:first-child:nth-last-child(1) {
      max-width: 100%;
    }
    /* two items */
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .discount-item {
      max-width: 47%;
    }
    /* three items */
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ .discount-item {
      max-width: 30%;
      @media (max-width: $phone-max) {
        max-width: 100%;
      }
    }
    /* four items */
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ .discount-item {
      max-width: 22%;
      @media (max-width: $phone-max) {
        max-width: 47%;
      }
    }
  }
}
