@import "_variables";
@import "_mixins";
.social-block {
  @include clearfix();
  margin-bottom: $large-spacing;

  @media (max-width: $phone-max) {
    display: flex;
    margin-bottom: 0;
    padding-bottom: $small-spacing;
    justify-content: center;
  }

  &.social-block--square {
    .social-block__item {
      color: white;
      border-radius: 5px;
      background-color: white;
      padding: 5px $default-spacing;
      font-size: 14px;
      display: inline-block;
      transition: background-color 0.3s ease-in-out;
      &.social-block__item--whatsapp {
        .styleme {
          fill: $whatsapp-color;
        }
      }
      @media (max-width: $phone-max) {
        padding: 5px;
      }
    }
    .social-block__icon {
      margin-right: $small-spacing;
      @media (max-width: 460px) {
        display: block;
        width: 24px;
        height: 24px;
        display: block;
        margin: 5px auto !important;
        .svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &.social-block--circle {
    .social-block__item {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: white;
      font-size: 16px;
      padding: 0;
      display: inline-block;
      transition: background-color 0.3s ease-in-out;
      &.social-block__item--facebook .svg {
        fill: $facebook-color;
        &:hover {
          fill: darken($facebook-color, 8%);
        }
      }
      &.social-block__item--twitter .svg {
        fill: $twitter-color;
        &:hover {
          fill: darken($twitter-color, 8%);
        }
      }
      &.social-block__item--mail .svg {
        fill: $mail-color;
        &:hover {
          fill: darken($mail-color, 8%);
        }
      }
      &.social-block__item--whatsapp {
        .social-block__icon {
          width: 25px;
          height: 25px;
        }
        .svg {
          width: inherit;
          height: inherit;
          fill: $whatsapp-color;
          &:hover {
            fill: darken($whatsapp-color, 8%);
          }
        }
      }
    }
    .social-block__icon {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .svg {
        width: 20px;
        height: 20px;
        fill: black;
      }
    }
  }

  &.social-block--border {
    .social-block__item {
      border: 1px solid $facebook-color;
      padding: $small-spacing;
      box-shadow: 1px 1px 5px $light-color-primary;
      &.social-block__item--facebook {
        border-color: $facebook-color;
        &:hover {
          background-color: darken($facebook-color, 10%);
          text-decoration: none;
          .svg {
            fill: white;
          }
        }
      }
      &.social-block__item--twitter {
        border-color: $twitter-color;
        &:hover {
          background-color: darken($twitter-color, 10%);
          text-decoration: none;
          .svg {
            fill: white;
          }
        }
      }
      &.social-block__item--mail {
        border-color: $mail-color;
        &:hover {
          background-color: darken($mail-color, 10%);
          text-decoration: none;
          .svg {
            fill: white;
          }
        }
      }
      &.social-block__item--whatsapp {
        border-color: $whatsapp-color;
        &:hover {
          background-color: $whatsapp-color;
          text-decoration: none;
          .svg {
            //fill: white;
            // no because it disappears
          }
        }
      }
    }
  }

  &.social-block--colored {
    .social-block__item {
      &:hover {
        span {
          color: white;
        }
      }
      &.social-block__item--facebook {
        background-color: $facebook-color;
        &:hover {
          background-color: darken($facebook-color, 10%);
          .svg {
            fill: white;
          }
        }
        .svg {
          fill: white;
        }
      }
      &.social-block__item--twitter {
        background-color: $twitter-color;
        &:hover {
          background-color: darken($twitter-color, 10%);
          .svg {
            fill: white;
          }
        }
        .svg {
          fill: white;
        }
      }
      &.social-block__item--mail {
        background-color: $mail-color;
        &:hover {
          background-color: darken($mail-color, 10%);
          .svg {
            fill: white;
          }
        }
        .svg {
          fill: white;
        }
      }
      &.social-block__item--whatsapp {
        background-color: darken($whatsapp-color, 10%);
        &:hover {
          background-color: darken($whatsapp-color, 20%);
          .svg {
          }
        }
        .svg {
        }
      }
    }
  }
}

.social-block__item {
  background-color: white;
  padding: $small-spacing;
  border-radius: 50%;
  margin-left: $small-spacing;
  font-size: 16px;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: $phone-max) {
    width: 100%;
    text-align: center;
    padding: 5px;
    margin-left: 5px;
  }
}

.social-block__icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  .svg {
    width: 16px;
    height: 16px;
    fill: white;
  }
}

.social-block__text {
  display: inline-block;
  display: none;
  vertical-align: middle;
  font-family: $font-family-body-qn;
  font-weight: 300;
}
