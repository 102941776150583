@import "_variables";
@import "_mixins";
.detail-page {
  .detail.detail--gallery {
    font-size: 16px;
    line-height: 1.6;
    .detail-body {
      @media (max-width: $phone-max) {
        padding: $default-spacing;
      }
    }
    .title {
    }
    .subtitle {
      font-size: 16px;
      line-height: 1.6em;
    }
  }
}
.detail-page {
  .gallery-image {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-bottom: $default-spacing;
    img {
      display: block;
      max-height: 500px;
      max-width: 100%;
      margin: auto;
      position: relative;
      z-index: 1;
      @media (min-width: $phone-max) {
        width: auto;
      }
      @media (max-width: $phone-max) {
        height: auto;
      }
    }
  }
  figure {
    margin-bottom: 0;
    picture {
      img {
      }
    }
    figcaption {
      font-size: 16px;
      color: $text-color-secondary;
    }
  }
  .gallery-image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.3;
    z-index: 0;
  }
  .gallery-image__background {
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(10px);
    overflow: hidden;
    opacity: 0.8;
    z-index: -1;
  }
  .gallery-image__number {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    color: white;
    font-size: 14px;
    z-index: 2;
    border-radius: 2px;
  }
  .gallery-image__caption {
    background-color: white;
    position: relative;
    z-index: 1;
    padding-top: 5px;
    font-size: 16px;
    line-height: normal;
    *:last-child {
      margin-bottom: 0 !important;
    }
    h3 {
      color: black;
      font-size: 20px;
      line-height: 1.6em;
      margin-bottom: 0;
      @media (max-width: $phone-max) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    p {
      font-size: 16px;
      line-height: 1.6em;
      color: black;
      margin-bottom: 0;
      @media (max-width: $phone-max) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
