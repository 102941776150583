@import "_variables";
@import "_mixins";
.dropdown {
  padding-left: 0;
  position: absolute;
  z-index: 10000;
  background-color: white;
  color: black;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .4);
  top: 50px;
  min-width: 220px;
  display: none;
  border-top: 2px solid #e91701;
  @include addBrandBorderColor;
  ul {
    padding-left: 0;
  }
  &.dropdown--right-side {
    right: -20px;
  }
  ul.dropdown--grid {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    padding: 10px;
    width: 350px;
    justify-content: flex-start;
    align-items: center;
    &.dropdown--grid--1 {
      padding: 0;
      width: 100%;
      display: block;
      li.dropdown__item {
        flex: 0 0 100%;
        line-height: normal;
        border-bottom: 1px solid #eee;
        a {
          padding: 10px;
          display: block;
          font-size: 14px;
          color: #000;
          text-transform: none;
          transition: color .2s ease-in-out,
          border-left .2s ease-in-out;
          border-left: 0;
          @include addBrandBorderColor;
          &:hover {
            text-decoration: none;
            border-left: 4px solid #e91701;
            @include addBrandColor();
            @include addBrandBorderColor;
          }
        }
      }
    }
    &.dropdown--grid--2 {
      width: 350px;
      max-width: 100%;
      li.dropdown__item {
        flex: 0 0 50%;
      }
    }
    &.dropdown--grid--3 {
      width: 400px;
      max-width: 100%;
      li.dropdown__item {
        flex: 0 0 33%;
      }
    }
    &.dropdown--grid--4 {
      width: 500px;
      max-width: 100%;
      li.dropdown__item {
        flex: 0 0 25%;
      }
    }
    li.dropdown__item {
      flex: 1 0 50%;
      border: 0;
      a {
        transition: color .2s ease-in-out;
        &:hover {
          border: 0;
          @include addBrandColor();
        }
      }
    }
  }
  &.dropdown--centered {
    @media(max-width: $phone-max) {
      top: 90px;
    }
    right: 0;
    left: 0;
  }
  &.dropdown--grid,
  .dropdown--grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .dropdown__item {
    display: block;
    line-height: normal;
    a {
      padding: $small-spacing;
      display: block;
      font-size: 14px;
      color: $text-color-primary;
      text-transform: none;
      transition: color .2s ease-in-out, border-left .2s ease-in-out;
      border-left: 0;
      border-color: $brand-color-main;
      @include addBrandBorderColor();
      &:hover {
        @include addBrandColor();
        text-decoration: none;
        border-left: 4px solid $brand-color-main;
        @include addBrandBorderColor();
      }
    }
    border-bottom: 1px solid $light-color-secondary;
  }
}

.placeholder {
  @media(max-width: $phone-max) {
    float: none !important;
  }
}
