@import "_variables";
@import "_mixins";
.footer {
  background-color: #000;
  padding-top: $default-spacing;
  @media (max-width: $desktop-min) and (min-width: $phone-max){
    width: 1024px;
  }
  .footer__content {
    color: white;
    padding: $default-spacing;
    @include container();
    @include clearfix();
    @media (max-width: $phone-max) {
      padding-left: $small-spacing;
      padding-right: $small-spacing;
    }
    a {
      font-weight: bold;
      color: white;
      margin-right: $small-spacing;
      text-decoration: underline;
    }
  }
  .footer__company-name {
    font-size: 18px;
    line-height: 1.4em;
  }
  .footer__company-description {
    font-size: 16px;
    line-height: 1.6em;
  }
  .footer__company-links {
    margin-bottom: $default-spacing;
  }
  .footer__company-copyright {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.7em;
    p {
      margin: 0;
    }
  }
  /* columns */
  .footer__column {
    float: left;
    width: 160px;
    padding-bottom: $large-spacing;
    position: relative;
    @media (max-width: $phone-max) {
      width: 100%;
      padding: $default-spacing 0;
      float: none;
      border-bottom: 1px solid #333;
    }
    &.footer__column--company {
      width: 500px;
      @media (max-width: $phone-max) {
        width: 100%;
      }
    }
    &.footer__column--open {
        .footer__list {
            display: block;
        }
    }
  }
  .footer__column-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2em;
    margin-bottom: 30px;
    @media(max-width: $phone-max) {
      margin-bottom: 0;
    }
  }
  .footer__list {
    @include clearUl();
    @media (max-width: $phone-max) {
      display: none;
      margin-top: 20px;
    }
  }
  .footer__list-item {
    @include clearLi();
    a {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.7em;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
