@import "_variables";
@import "_mixins";

.card.card--video {
  .card__image {
    @include clearfix();
    position: relative;
    display: block;
    .image__container {
      position: relative;
    }
    img {
      display: block;
    }
    iframe {
      position: absolute;
      top: 0;
    }
  }
}

.overlay--video::after {
  content: "";
  display: block;
  opacity: 0.8;
  height: 20px;
  max-width: 29.8%;
  margin-top: 17px;
  margin-left: 17px;
  background-repeat: no-repeat;
}
[href*=quotidiano\.net] .overlay--video::after {
  background-image: url(../img/svg/logo_white_quotidiano_nazionale_spaziato.svg)
}
[href*=ilrestodelcarlino\.it] .overlay--video::after {
  background-image: url(../img/svg/logo_white_ilrestodelcarlino.svg)
}
[href*=lanazione\.it].overlay--video::after {
  background-image: url(../img/svg/logo_white_lanazione.svg)
}
[href*=ilgiorno\.it].overlay--video::after {
  background-image: url(../img/svg/logo_white_ilgiorno.svg)
}
[href*=iltelegrafolivorno\.it] .overlay--video::after {
  background-image: url(../img/svg/logo_white_iltelegrafo.svg)
}
