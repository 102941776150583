/* ---------------------------------------------------------------------------------------------------------
generic ads
--------------------------------------------------------------------------------------------------------- */
.adv-slot-container {
  div[style*='height'] {
    iframe {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  div[style*='height: 0'],
  div[style*='height: 1px'] {
    margin: 0;
    iframe {
      margin: 0;
    }
  }
  iframe {
    margin-bottom: 10px;
  }
}

.autopromo-side {
  margin-bottom: $default-spacing;
}

/* ---------------------------------------------------------------------------------------------------------
above the fold ads
--------------------------------------------------------------------------------------------------------- */
// leaderboard
#div-gpt-ad-leaderboard-728-or-skin {
  background: rgba(0, 0, 0, 0.05);
  iframe {
    margin: 13px auto;
  }
}

// fascione elezioni
iframe#fascione_elezioni {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
  display: block;
}

/* ---------------------------------------------------------------------------------------------------------
sidebar ads
--------------------------------------------------------------------------------------------------------- */
// video box top
#div-gpt-ad-videobox-top-300x250 {
  div[style*='height'] {
    margin-top: 0;
  }
  iframe {
    margin-bottom: $default-spacing;
    margin-top: 0;
  }
}
// dirty amp on mobile related cards
.sidebar-group amp-ad {
  @media (max-width: $phone-max) {
    margin-bottom: 10px;
  }
}

/* ---------------------------------------------------------------------------------------------------------
detail ads
--------------------------------------------------------------------------------------------------------- */
.detail-page #div-gpt-ad-videobox-top-300 {
  margin-top: 0;
}

/* masthead */
#div-gpt-ad-masthead-990 {
  div[style*='height'] {
    margin-top: 0 !important;
    box-sizing: content-box;

    iframe {
      margin-bottom: 0;
    }
  }
}

/* masthead and title */
.section-title-container + #dfp-masthead iframe {
  margin-bottom: 0;
  margin-top: 0;
}

.detail-page {
  #div-gpt-ad-videobox-bottom-300 {
    margin-bottom: $default-spacing;
  }
}

/* detail ads mobile */
#div-gpt-ad-300x250top-mobile > div > [style*='height'] {
  margin: 10px auto;
}

// video box top detail
#div-gpt-ad-videobox-top-300 {
  margin-bottom: $default-spacing;
  div[style*='height'] {
    margin-top: 0;
    .is_stuck & {
      margin-top: 10px;
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------
mobile ads
--------------------------------------------------------------------------------------------------------- */
#div-gpt-ad-50-mobile {
  max-width: 100%;
}

/* underticker */
[data-wt-area='underaticker'] {
  iframe[src*='match-center'] {
    padding-top: 10px;
    box-sizing: content-box;
  }
}
