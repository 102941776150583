@import "_variables";
@import "_mixins";
.side-column {
  float: left;
  width: 300px;
  will-change: min-height;
  @include clearfix();
  @media (max-width: $phone-max) {
    display: none;
    width: auto;
  }
  img {
    width: 100%;
    height: auto;
  }
  .card__text {
    padding: 10px 0;
    margin-bottom: 10px;
  }
  .card--image-small {
    .card__image {
      @media (min-width: $tablet-min) {
        width: 30%;
      }
    }
    .card__text {
      @media (min-width: $tablet-min) {
        width: 70%;
      }
    }
  }
  .sidebar__inner {
  }
}
