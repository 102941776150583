@import "_variables";
@import "_mixins";
.tags {
    margin-bottom: $large-spacing;
    .tags__title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2em;
        margin-bottom: $small-spacing;
        text-transform: uppercase;
    }
}

.tag {
    display: inline-block;
    //border: 1px solid $light-color-secondary;
    padding: 2px 5px;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin-right: 5px;
    margin-bottom: 5px;
    transition: background-color .3s ease-in-out;
    &:hover {
        background-color: $light-color-secondary;
        text-decoration: none;
        .tag__icon {
            .svg, .svg .styleme {
                fill: black;
            }
        }
        .tag__name {
            color: black;
        }
    }
    &.tag--linked {
        border-color: $brand-color-main;
        @include addBrandBorderColor();
        &:hover {
            @include addBrandBackgroundColor();
            .tag__icon {
                .svg, .svg .styleme {
                    fill: white;
                }
            }
            .tag__name {
                color: white;
            }
        }
        .tag__icon {
            .svg, .svg .styleme {
                fill: $brand-color-main;
                @include addBrandFillColor();
            }
        }
        .tag__name {
            @include addBrandColor();
        }
    }
    .tag__icon {
        margin-right: 6px;
        .svg, .svg .styleme {
            transition: fill .3s ease-in-out;
        }
    }
    .tag__name {
        color: $dark-color-secondary;
        font-family: "Roboto condensed";
        font-size: 14px;
        line-height: 1.2em;
        transition: color .3s ease-in-out;
    }
}
