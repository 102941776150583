@import "_variables";
@import "_mixins";
/* related */

.related {
    @include addBrandColor();
    display: inline-block;
    margin-left: $small-spacing;
    text-decoration: none;
    // text-transform: uppercase;
    // font-variant: small-caps;
    // font-variant: all-small-caps;
    .related__label,
    .related__text {
        transition: color 0.2s ease-in-out;
    }
    &:hover {
        .related__label {
            @include addBrandColor();
        }
        .related__text {
            @include addBrandColor();
            text-decoration: underline;
        }
    }
}
