@import "_variables";

.detail-page--document {
  .main-content.main-content--bg {
    background: #fafafa;
    padding-top: 40px;
    padding-bottom: 40px;
    .main-column {
      background: white;
      padding: 40px;
      border-radius: 10px;
      box-shadow: 1px 1px 23px rgba(0, 0, 0, 0.1);
      width: 700px;
    }
  }
  .main-column {
    padding-top: 40px;
    margin: 0 auto;
    text-align: justify;
  }
  .document-section {
    @media (max-width: $phone-max) {
      padding: 20px;
    }

    ol {
      padding-left: 20px;
      li {
      }
    }
    h2,
    h3 {
      margin-bottom: 20px;
    }
    h4 {
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      line-height: 1.6em;
      text-align: justify;
    }
    ol {
      display: block;
      &[style*="list-style-type:decimal"] {
        display: block !important;
      }
      h2 {
        span {
          font-size: 26px;
        }
      }
      li {
      }
    }
    ul {
      &[style*="list-style-type:disc"] {
        padding-left: 20px;
      }
      h4 {
        font-weight: bold;
      }
    }
    table {
      border: 1px solid #ddd;
      margin-bottom: 20px;
      th {
      }
      tr {
        border: 1px solid #ddd;
        &:nth-child(even) {
          td {
            background-color: #fafafa;
          }
        }
      }
      td {
        padding: 20px;
        p {
          font-size: 13px;
          margin-bottom: 0;
        }
      }
      @media (max-width: 768px) {
        tr {
          display: block;
          &.hide-mobile {
            display: none;
          }
          td {
            display: block;
            &:nth-child(2) {
              &:before {
                content: "Finalità";
                display: block;
                padding-bottom: 20px;
              }
            }
            &:nth-child(3) {
              &:before {
                content: "Base Giuridica";
                display: block;
                padding-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
  .copyright-notice {
    padding-left: 20px;
  }
}
