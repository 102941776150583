@import "_variables.scss";
@import "_mixins.scss";
// base styles
body {
  .lg-outer {
    z-index: 100500;
    &.lg-visible {
      @media (min-width: $desktop-min) {
        background-color: #333;
      }
    }
    .lg {
      @media (min-width: $desktop-min) {
        background-color: black !important;
        max-width: 70%;
        max-width: calc(100% - 340px) !important;
        margin-left: 0;
      }
    }
    .lg-sub-html {
      @media (min-width: $desktop-min) {
        max-width: 70%;
        max-width: calc(100% - 340px) !important;
        padding-right: 80px;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        line-height: 1.3em;
      }
    }
    #div-gpt-ad-videobox-top-300 {
      margin-bottom: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 999999999;
      border: 1px solid $brand-color-main;
      @include addBrandBorderColor();
    }
  }
}

#lg-download {
  display: none;
}
