@import "_variables";
@import "_mixins";

.qn-blog-header-container {
  padding-top: 20px;
  display: block;
  text-decoration: none;
  margin-bottom: 20px;
  @include clearfix();
  border-bottom: 1px solid $light-color-primary;
  padding-bottom: 20px;
  @media(max-width: $phone-max) {
    text-align: center;
    border-bottom: 0;
  }
  &:hover {
    color: black;
    text-decoration: none;
  }
  &.header-with-quote {}
}

.qn-blog-header {}

.qn-blog-header-info {
}

.qn-blog-header-image-container {
  float: left;
  width: 120px;
  margin-right: 20px;
  @media(max-width: $phone-max) {
    float: none;
    margin: auto;
  }
}

.qn-blog-header-image {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  box-shadow: 1px 1px 1px #ddd;
  img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.qn-blog-header-name {
  color: black;
  font-family: "Roboto Condensed";
  font-size: 30px;
  font-weight: 700;
  margin-right: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
  @media(max-width: $phone-max) {
    width: 100%;
    margin-right: 0;
  }
}

.qn-blog-header-subtitle {
  color: #575757;
  font-family: "Roboto Condensed";
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
  display: inline-block;
  @media(max-width: $phone-max) {
    width: 100%;
  }
}

.qn-blog-header-quote {
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  text-decoration: none;
  padding-left: 140px;
  @media(max-width: $phone-max) {
    width: 100%;
    padding: 0 20px;
  }
}
