@import "_variables";
@import "_mixins";
.insideartplayer.player {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.player_wrapper {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  &.very-large {
      position: initial;
  }
}

.detail-page--article .player_wrapper {
    margin-bottom: 30px;
    margin-top: 30px;
}

.gallery-box-link {
  padding: 5px 1.53846%;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  @media(max-width: $phone-max) {
    margin: 0;
    padding: 10px;
  }
  .label {
    font-family: "Roboto Condensed";
    @include addBrandColor();
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
  }
  .gallery-box-head .gallery-box-title {
    color: $text-color-primary;
    font-size: 24px;
    line-height: 120%;
    padding: 0 0 5px;
  }
  .gallery-box-img {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 1px solid #ddd;
    a img {
      max-width: 100%;
      opacity: 0.8;
      cursor: pointer;
      @media(max-width: $phone-max) {
        object-fit: cover;
      }
      &:hover {
        opacity: 1;
      }
    }
    a:after {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      margin-left: -50px;
      margin-top: -50px;
      background: url("{{ STATIC_URL }}img/svg/gallery_w.svg") no-repeat 50% 50%;
      background-size: 100px 100px;
      z-index: 10;
      cursor: pointer;
    }
  }
  .card__image--gallery .image__container {
    border: 1px solid #ccc;
  }
}
