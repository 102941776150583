@import "_variables";
@import "_mixins";

html {
  @media (max-width: $desktop-min) and (min-width: $tablet-min) {
    width: 1024px;
  }
}

body {
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  @media (max-width: $desktop-min) and (min-width: $tablet-min) {
    width: 1024px;
    overflow-x: hidden;
  }
  &.body--block-scroll {
    overflow: hidden;
    position: fixed;
    max-width: 100%;
    width: 100%;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 10;
    }
  }
  @media (max-width: $phone-max) {
    margin-top: 90px !important;
  }
  .main-content {
    clear: both;
    @include clearfix();
    @media (max-width: $phone-max) {
      padding-top: 0;
    }
  }
  &.body-skin {
    .header-wrapper {
      @include containerForSkin();
    }
    #div-gpt-ad-leaderboard-728-or-skin {
      background-color: transparent;
    }
    .network-menu {
      .network-menu__inner {
        @include containerForSkin();
        @include containerBorderForSkin();
      }
    }
    .edition {
      @include containerBorderForSkin();
      width: 100%;
    }
    .edition-menu, .trends-menu {
      @include containerBorderForSkin();
    }
    .submenu {
      @include containerBorderForSkin();
    }
    .footer {
      @include containerForSkin();
    }
    .wrapper {
      @include containerBorderForSkin();
    }
    .additional-articles {
      @include containerForSkin();
      @include containerBorderForSkin();
    }
  }
}

h1 {
  font-size: 30px;
}

h2 {
}

.main-column {
  width: 660px;
  max-width: 100%;
  .home-page & {
    @media (max-width: $phone-max) {
      background-color: $light-color-secondary;
      float: none;
      margin: auto;
    }
  }
}

a {
  @include addBrandColor();
  :hover {
    @include addBrandColorDark();
  }
}

::selection {
  background: $brand-color-transparent;
  @include addBrandBackgroundColorTransparent();
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: $brand-color-transparent;
  @include addBrandBackgroundColorTransparent();
  /* Gecko Browsers */
}

/* temporary styles */

.card__title a.related .card__title a.related {
  border: 1px solid $light-color-primary;
  padding: 5px $small-spacing;
  font-size: 16px;
  border-radius: 7px;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    @include addBrandBackgroundColor();
    color: white;
  }
}

.header-wrapper {
  z-index: 10000;
}

// utility classes
.hide-on-mobile,
.hide-mobile {
  @include hideMobile();
}

.hide-on-desktop {
  @include hideDesktop();
}
