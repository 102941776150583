@import '_variables';
@import '_mixins';
@import '_animations';

@import 'meteoHeader';

$height-logo-edition: 60px;
//qn
$qn-logo-height: 47px;
// irdc
$irdc-logo-height: 54px;
// ig
$ig-logo-height: 42px;
//ln
$ln-logo-height: 42px;
//itl
$itl-logo-height: 42px;

// si vede la skin sotto perche' i nav son dispari di un pixel, toppa orribile
.desktop-menu nav {
  background-color: white !important;
}

.edition {
  position: relative;
  text-align: center;
  @include container(white);
  @include hideMobile();
  .edition__header {
    padding-top: 22px;
    padding-bottom: 22px;


    // qn desktop nazionale
    .qn & {
      padding-top: 28px;
      .svg {
        height: $qn-logo-height;
      }
    }

    // irdc desktop nazionale
    .ilresto & {
      .svg {
        height: $irdc-logo-height;
      }
    }
    // edizioni locali con logo citta'
    .ilresto.edizione & {
      .svg {
        height: 42px;
      }
      .citta .svg {
        height: $height-logo-edition;
        margin-top: 4px;
      }
    }

    // ilgiorno, lanazione desktop nazionale
    .ilgiorno &,
    .lanazione & {
      padding-top: 28px;
      padding-bottom: 24px;
      .svg {
        height: $ig-logo-height;
      }
    }

    // irdc desktop nazionale
    .iltelegrafo & {
      padding-top: 27px;
      padding-top: 25px;
      .svg {
        height: $itl-logo-height;
      }
    }

    // edizioni locali con logo citta'
    .ilgiorno.edizione &,
    .lanazione.edizione & {
      .svg {
        height: 34px;
      }
      .citta .svg {
        height: $height-logo-edition;
        margin-top: 4px;
      }
    }

    .logo {
      display: block;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      text-decoration: none;
      .svg {
        display: inline-block;
        vertical-align: bottom;
      }
      a {
        display: block;
      }
    }
    .edition-header__subtitle {
      display: inline-block;
    }
    .edition-header__subtitle-item {
      display: inline-block;
      vertical-align: middle;
      &.edition-header__title {
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        display: inline-block;
        background-color: #ddd;
        border-radius: 50%;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 3px;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    .edition-header__date {
      font-weight: normal;
    }
    .edition-header__last-update {
      display: inline-block;
      vertical-align: middle;
      font-weight: 300;
      strong {
        @include addBrandColor();
      }
    }
  }
}

.edition {
  body.edizione.ilresto & {
    .svg {
    }
  }
  body.edizione.lanazione & {
    .svg {
    }
  }
  body.edizione.ilgiorno & {
    .svg {
    }
  }
  body.edizione.iltelegrafo & {
    .svg {
    }
  }
}

body.detail-page .edition,
body.error-page .edition {
  body.ilresto &,
  body.detail-page.ilresto & {
    .svg {
    }
  }
  body.lanazione &,
  body.detail-page.lanazione & {
    .svg {
    }
  }
  body.ilgiorno &,
  body.detail-page.ilgiorno & {
    .svg {
    }
  }
  body.iltelegrafo &,
  body.detail-page.iltelegrafo & {
    .svg {
    }
  }
  .edition__header {
    padding-top: 15px;
    padding-bottom: 15px;
    .edition-header__subtitle {
      display: none;
    }
    .svg {
      @media (max-width: $phone-max) {
      }
    }
  }
}

.social {
  display: inline-block;
  .social__icon {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 5px;
    border-radius: 50%;
    margin-right: $small-spacing;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &.social__icon--twitter {
      background-color: #55acee;
    }
    &.social__icon--youtube {
      background-color: #cd201f;
    }
    &.social__icon--facebook {
      background-color: #3b5999;
    }
    &.social__icon--instagram {
      background-color: #e4405f;
    }
    &.social__icon--stroke {
    }
    .svg {
      fill: white;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &.social--no-bg {
    .social__icon {
      background-color: transparent;
      padding: 0;
      .svg {
        fill: none;
      }
      &.social__icon--twitter {
        .svg {
          fill: #55acee;
        }
      }
      &.social__icon--youtube {
        .svg {
          fill: #cd201f;
        }
      }
      &.social__icon--facebook {
        .svg {
          max-width: 24px;
          max-height: 24px;
          fill: #3b5999;
        }
      }
      &.social__icon--instagram {
        .svg {
          fill: #e4405f;
        }
      }
    }
  }
}
