@import "_variables";
@import "_mixins";

.now-on-tv {
}
.channel {
    padding: 10px 0;
    @include clearfix();
    border-bottom: 1px solid #D8D8D8;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    &:hover {
        text-decoration: none;
        .program-name {
            color: $brand-color-main;
        }
    }
    .channel-logo {
        float: left;
        width: 40px;
        height: 40px;
        position: relative;

        img {
            max-width: 100%;
            max-height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

    }
    .program {
        float: left;
        width: calc(100% - 40px);
    }
    .program-info {

    }
    .program-start {
        display: inline-block;
        color :$text-color-primary;
        font-size: 14px;
        transition: color .3s ease-in-out;
        margin-right: 10px;
    }
    .program-category {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 200;
        font-size: 14px;
        color :$text-color-secondary;
    }
    .program-name {
        color :$text-color-primary;
        line-height: normal;
        transition: color .3s ease-in-out;
    }
}
