@import "_variables";
@import "_mixins";
.mobile-navigation-container {
  position: fixed;
  z-index: 1;
  display: none;
  border-top: 1px solid #ddd;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  &.mobile-navigation--open {
    display: block;
  }
  .footer {
    background-color: #eee;
    padding: 10px;
    .footer__column {
      border-bottom: 0;
      >div {
        font-size: 16px;
        color: $text-color-muted;
        a {
          color: $text-color-secondary;
          margin-right: 10px;
        }
      }
    }
  }
}

.mobile-navigation-container__inner {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mobile-navigation {
  background-color: #eee;
  &.mobile-navigation--separator {
    border-bottom: 4px solid #ddd;
  }
  &.mobile-navigation--background {
    background-color: #f6f6f6;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  &.mobile-navigation--bold {
    .mobile-navigation__item {
      font-weight: 700;
    }
  }
  .mobile-navigation__item {
    background-color: white;
    display: block;
    padding: 10px;
    text-transform: uppercase;
    color: $text-color-primary;
    border-bottom: 1px solid #eee;
    position: relative;
    &.active {
      @include addBrandColor();
      a {
        @include addBrandColor();
      }
    }
    &.mobile-navigation--child {
      background-color: #eee;
      font-size: 14px;
      font-weight: 400;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    &.mobile-navigation__item--featured {
      color: $brand-color-main;
      @include addBrandColor();
    }
    .svg {
      max-width: 120px;
      height: 25px;
      line-height: normal;
      margin: 8px 0;
    }
    &:nth-child(n+6) {
      .svg {
        max-height: 13px;
      }
    }
    .external-link-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      &:after {
        content: " ";
        position: absolute;
        top: -8px;
        right: 0;
        width: 16px;
        height: 16px;
        background: transparent url('../img/svg/external-link.svg') no-repeat 50% 50%;
        background-size: contain;
        opacity: .25;
      }
    }
  }
}

.mobile-menu {
  .dropdown {
    .dropdown__item {
      a {
        font-weight: 700;
      }
    }
  }
}

// search
.mobile-navigation-search {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  line-height: normal;
  @include clearfix();
  button {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    right: 0;
    background-color: white;
    border: none;
    .svg {
      width: 20px;
      opacity: .4;
    }
  }
  [type="search"] {
    width: 100%;
    width: calc(100% - 40px);
    border: none;
    padding: 10px;
    text-transform: uppercase;
    min-height: 40px;
  }
}

.mobile-navigation-search__input {
  width: 100%;
  width: calc(100% - 40px);
  border: none;
  padding: 10px;
  text-transform: uppercase;
  min-height: 40px;
}

.mobile-navigation-search__button {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  right: 0;
  background-color: white;
  border: none;
  .svg {
    width: 20px;
  }
}

// accordion
.accordion {
  background-color: white;
  border-radius: 5px;
  @media (max-width: $phone-max) {
    border-radius: 0;
    border-bottom: 1px solid #ddd;
  }
  overflow: hidden;
  &.accordion--open {
    .accordion__header-icon {
      transform: translateY(-50%) rotate(180deg);
    }
    .accordion__dropdown {
      border-top: 1px solid #ddd;
    }
  }
  .accordion__header-icon {
    background-image: url('../img/arrow-bottom.png');
    background-size: 100%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 10px;
    width: 12px;
    height: 12px;
    transition: transform 0.3s ease-in-out;
  }
  ul {
    display: block;
    padding-left: 0;
    li {
      display: block;
    }
  }
  .accordion__header {
    @include clearfix();
    padding: 10px;
    // box-shadow: -1px 5px 10px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    user-select: none;
    // border-bottom: 1px solid #ddd;
  }
  .accordion__header-label {
    float: left;
    font-weight: 700;
    min-height: 25px;
    text-transform: uppercase;
    &.active {
      @include addBrandColor();
    }
    .svg {
      width: 100%;
      max-width: 120px;
      max-height: 25px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .accordion__dropdown {
    display: none;
    background-color: #f4f4f4;
    .accordion__dropdown--grid,
    .accordion__ {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      li {
        display: block;
        display: flex; // flex: 0 0 33%;
        text-align: left;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        justify-content: flex-start;
        a {
          font-size: 14px;
          padding: 5px 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          background-color: white;
        }
      }
    }
    .accordion__item {
      display: block;
      color: $text-color-secondary; //border-bottom: 1px solid #eee;
      padding: 5px 10px;
      font-size: 14px;
      border-bottom: 1px solid #ddd;
      &.active {
        @include addBrandColor;
      }
      &:hover {
        color: $text-color-primary;
      }
    }
  }
}
