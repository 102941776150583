@import "_variables";
@import "_mixins";

.title {
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  transition: color 0.2s ease-in-out;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 30px;
  line-height: 1.3;
  
  a {
    display: inline;
    margin-right: 10px;
  }
  .related {
    margin-left: 0;
    margin-right: 10px;
  }
  .title__link {
    display: inline;
    line-height: 1.2em;
    &:hover {
      color: $dark-color-secondary;
    }
  }
  a.title__link {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
}

.title__related {
  display: inline-block;
  @include addBrandColor();
  .related {
    font-size: 24px;
    margin-left: $small-spacing;
    font-weight: inherit;
    text-transform: uppercase;
  }
}

.super-title {
  font-size: 20px;
  margin-bottom: 20px;

  @media (max-width: $phone-max) {
    font-size: 1.3em;
    margin-top: 20px;
  }

  .super-title__colored {
    font-size: inherit;
    @include addBrandColor();
    display: inline-block;
    font-weight: 700;
    margin-right: 5px;
    text-transform: uppercase;
  }
  .super-title__text {
    font-size: inherit;
    color: black;
    display: inline;
    font-weight: 300;
    text-transform: uppercase;
  }
}
