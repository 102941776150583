@import "_variables";
@import "_mixins";
.button {
  font-family: "Roboto Condensed", sans-serif;
  border: 1px solid $light-color-primary;
  background-color: #ffffff;
  color: #000000;
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  padding: $small-spacing $default-spacing;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &:hover {
    background-color: darken(white, 5%);
  }
  &:active,
  &:focus {
    outline: none;
    box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
  } // size
  &.button--full-width {
    font-size: 18px;
    width: 100%;
  }
  &.button--small {
    text-transform: none;
    font-size: 14px;
    padding: 5px 10px;
  }
  &.button--big {
    font-size: 16px;
  } // style
  &.button--white {
  }
  &.button--red {
    @include addBrandBackgroundColor();
    color: white;
    &:hover {
      @include addDarkBrandBackgroundColor();
    }
    &:active,
    &:focus {
      outline: none;
      box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
    }
  }
  &.button--link {
    background-color: none;
    border: none;
    text-transform: none;
    text-decoration: underline;
  }
  &.button--rounded {
    border-radius: 3px;
  }
  &.button--cta {
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 18px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    @include addBrandBackgroundColor();
    border-radius: 30px;
    padding: 20px 10px;
    max-width: 300px;
    color: white;
    display: block;
    margin: auto;
    margin-bottom: 200px;
    margin-bottom: 20vh;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      @include addDarkBrandBackgroundColor();
    }
  }
}
