@import "_variables";
@import "_mixins";

$ais-search-box-height: 60px;

.results-search-block {
  @media (max-width: $phone-max) {
    padding-left: 10px;
    padding-right: 10px;
  }
  padding-top: 30px;
  .ais-search-box {
    position: relative;
    &:after {
      content: url('../img/svg/search.svg');
      width: $ais-search-box-height;
      height: $ais-search-box-height;
      padding: 10px;
      display: block;
      position: absolute;
      padding: 20px;
      top: 0;
      right: 0;
      border: 1px solid #ddd;
      border-radius: 0 5px 5px 0;
    }
    input {
      width: 100%;
      height: $ais-search-box-height;
      padding: 10px 0;
      border: 0;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding-left: 20px;
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.filters-block {
  margin-top: 20px;
  @media (max-width: $phone-max) {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .filters-type-block {
    .ais-refinement-list--item {
      display: inline-block;
      padding: 5px 10px;
      margin-right: 10px;
      border-radius: 10px;
      transition: all .3s ease-in-out;
      &:hover {
        background-color: #eee;
      }
      &.ais-refinement-list--item__active {
        background-color: black;
        a {
          font-weight: 700;
          color: white;
          em {
            text-transform: uppercase;
            font-weight: 700;
            color: white;
          }
        }
      }
      a {
        color: $text-color-primary;
        font-family: "Roboto Condensed", sans-serif;
        text-decoration: none;
        em {
          font-weight: 200;
          color: $text-color-secondary;
        }
      }
    }
  }
}

.results-block {
  width: 660px;
  max-width: 100%;
  @media (max-width: $phone-max) {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ais-hits--item {
  .card-no--image {
    .card__subject.card__subject--top {
      @media (max-width: $phone-max){
        position: static;
      }
    }

    .card__text {
      width: 100%;
    }
  }
  .card__title {
    line-height: normal;
    em {
      color: $brand-color-main;
      @include addBrandColor();
      font-style: normal;
    }
  }
  .card__abstract {
    .abstract {
      @include limitTextLines(3, 1.2);
      em {
        color: $brand-color-main;
        @include addBrandColor();
        font-style: normal;
      }
    }
  }
  .card.card--gallery .image__container img,
  .card__image--gallery .image__container {
    img {
      max-height: 100%;
    }
  }
  .card__footer {
    .signature {
      @media(max-width: $phone-max){
        margin-right: 10px;
      }
    }
    .date {
      float: none;
      width: auto;
    }
  }

}

.pagination-block {
  width: 660px;
  max-width: 100%;
  @media (max-width: $phone-max) {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ais-pagination {
    display: block;
    display: flex;
    padding-left: 0;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-bottom: 20px;
    @media (max-width: $phone-max) {
      flex-wrap: wrap;
    }
    .ais-pagination--item {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      @media (min-width: $tablet-min) {
        margin-right: 10px;
        margin-bottom: 10px;
      }
      &.ais-pagination--item__first {}
      &.ais-pagination--item__previous {}
      &.ais-pagination--item__active {
        .ais-pagination--link {
          font-weight: 700;
          background-color: black;
          color: white;
        }
      }
      &.ais-pagination--item__page {
        @media (max-width: 450px) {
          display: none;
        }
      }
      .ais-pagination--link {
        @media (min-width: $tablet-min) {
          border: 1px solid #ddd;
          padding: 5px 15px;
          border-radius: 5px;
        }
        @media (max-width: 450px) {
          border: 1px solid #ddd;
          padding: 5px 15px;
          border-radius: 5px;
        }
        padding: 10px;
        color: $text-color-primary;
        text-decoration: none;
        display: inline-block;
        font-family: "Roboto Condensed",
        sans-serif;
      }
    }
  }
}

.ais-hits.ais-hits__empty {
  .result-item {
    padding: 100px 0;
    text-align: center;
    width: 100%;
    color: #575757;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    border-radius: 5px;
  }
}
