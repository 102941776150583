@import "_variables";
@import "_mixins";

$breadcrumb-height: 25px;

.breadcrumbs {
  position: relative;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    background: $light-color-primary;
    content: '';
  }

  .breadcrumbs__container {
    margin: 0;
    padding: 10px 0;
    white-space: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .breadcrumb {
    position: relative;
    display: inline-block;
    margin-left: 15px;
    font-size: 16px;
    font-weight: 400;

    &:first-child {
      margin-left: 0;
    }

    &:not(:first-child) {
      font-weight: 700;

      &::before {
        margin-right: 13px;
        color: #666;
        content: '>';
      }
    }

    &:last-child {
      .breadcrumb__link {
        @include addBrandColor();
      }
    }
  }

  .breadcrumb__link {
    display: inline-block;
    color: #666;
    text-decoration: none;
    height: $breadcrumb-height;
    line-height: $breadcrumb-height;

    &:hover {
      @include addBrandColor();
    }
  }

  &.breadcrumbs--has-premium-icon {
    .breadcrumb {
      &:last-child {
        .breadcrumb__link {
          position: relative;
          padding-left: 27px;
  
          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 20px;
            height: 20px;
            background-image: url('../img/paywall-blue.svg');
            background-size: 100%;
            transform: translateY(-50%);
            content: '';
    
            body.qn & {
              background-image: url('../img/paywall.svg');
            }
    
            body.iltelegrafo & {
              background-image: url('../img/paywall-telegrafo.svg');
            }
          }
        }
      }
    }
  }
}
