@import "_variables";
@import "_mixins";

.widget{
    &.widget--placeholder{
        background-color: $light-color-secondary;
        color: $dark-color-secondary;
        padding: $default-spacing;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        height: 300px;
        margin-bottom: $default-spacing;
    }
}
