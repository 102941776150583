@import "_variables";
@import "_mixins";

$number-width: 40px;
.oroscopo-heading {
  margin-bottom: 20px;
  margin-top: 40px;
  position: relative;
  &:first-child {
    margin-top: 20px;
  }
  .oroscopo-heading__number {
    background-color: $brand-color-main;
    @include addBrandBackgroundColor();
    text-align: center;
    color: white;
    width: $number-width;
    height: $number-width;
    line-height: $number-width;
    border-radius: 50%;
    font-weight: 700;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .oroscopo-heading__sign {
    display: block;
    margin-left: $number-width + 10;
    font-weight: 700;
    text-transform: uppercase;
  }
  .oroscopo-heading__stars {
    vertical-align: middle;
    float: right;
    margin-bottom: 0;
    padding-right: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    font-family: AppleSDGothicNeo-Regular, "lucida grande", tahoma, verdana,
      arial, sans-serif, "Segoe UI Symbol", NotoColorEmoji, EmojiSymbols,
      Symbola, Noto, "Android Emoji", AndroidEmoji, "Arial Unicode MS",
      "Zapf Dingbats", AppleColorEmoji, "Apple Color Emoji";
  }
  hr {
    margin-top: -1px;
    margin-left: $number-width + 10;
    clear: both;
  }
}
