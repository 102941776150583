@import "_variables";
@import "_mixins";
.pagebottom .sidebar-group {
  margin-bottom: 0;
}
.sidebar-group {
  margin-bottom: $default-spacing;
  counter-reset: section;
  &.sidebar-group {
    .card__image {
      @media (max-width: $phone-max) {
        a {
          display: block;
        }
      }
    }
  }
  &.sidebar-group--small-thumbnail {
    .card {
      .card__text {
        margin-bottom: 0;
        padding: 0;
      }
      .card__image {
        min-height: 90px;
        overflow: hidden;
        position: relative;
        background-color: $light-color-secondary;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          min-height: 90px;
          min-width: 90%;
          height: auto;
          width: auto;
        }
      }
      .card-owner {
        color: $text-color-primary;
        font-weight: 300;
        display: block;
        padding-left: $small-spacing;
        font-size: 14px;
        &:hover {
          text-decoration: none;
        }
        .card-owner__name {
          line-height: normal;
          text-transform: uppercase;
        }
      }
      .title {
        font-size: 14px;
        padding-left: $small-spacing;
      }
    }
  }
  &.sidebar-group--slider {
    .card__text {
      .card__title h3 {
        a {
          @include limitTextLines(3, 1.2);
        }
      }
    }
  }

  .layout_widget {
    &.edizioni_preview {
      .widget_wrapper {
        display: flex;
        flex-wrap: wrap;

        > .card {
          flex: 1 1 100%;
          overflow: hidden;

          @media (min-width: $tablet-min) {
            display: block;
            flex: 1 1 23.5%;
            margin-right: 1%;
            margin-left: 1%;

            &:nth-child(4n+1) {
              margin-left: 0;
            }

            &:nth-child(4n+4) {
              margin-right: 0;
            }

            &:nth-last-child(-n+4) {
              margin-bottom: 0;
              border-bottom: none;
            }

            .card__image {
              width: 100%;
              max-height: 82px;
              min-height: 0;
            }

            .card__text {
              // float: none;
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }

          .card__text {
            .card__title .title {
              font-size: 15px;
              font-weight: 500;
              a {
                margin-right: 0;
              }
            }
          }

          // &:not(.card--no-image) .card__subject.card__subject--top .subject:before {
          //   top: 84px;
          // }

          .card__abstract, .card__footer, .signature {
            display: none;
          }
        }
      }
    }

    &.colsx-layout_widget {
      position: relative;
      margin-top: 40px;
      padding: 30px 15px 15px;
      border: 1px solid $dark-color-secondary;

      @media (max-width: $tablet-min) {
        margin-right: 10px;
        margin-left: 10px;
      }

      @media (min-width: $tablet-min) {
        border-color: #d8d8d8;
      }

      &.luce {
          > div:first-child .title > a > img {
              height: 32px;
          }
      }

      &::after {
        display: table;
        clear: both;
        content: '';
      }

      > div:first-child .title {
        position: absolute;
        top: 0;
        display: block;
        transform: translateY(-46%);

        > a, > span {
          display: inline-block;
          margin: 0;
          padding: 10px;
          background: $light-color-secondary;

          @media (min-width: $tablet-min) {
            background: #fff;
          }
        }

        > span {
          display: block;
          margin-top: -10px;
          font-size: 28px;
        }

        img {
          display: block;
          height: 18px;
          width: auto;
        }
      }
    }
  }

  .sidebar-group__title-bar {
    @include clearfix();
    margin-bottom: $small-spacing;
    .title {
      font-size: 18px;
    }
    &:after {
      width: 150px;
      height: 3px;
      @include addBrandBackgroundColor();
    }
  }
  .sidebar-group__title {
    float: left;
    text-transform: uppercase;
  }
  .sidebar-group__link {
    color: inherit;

    &:hover {
      color: $dark-color-secondary;
      text-decoration: none;
    }
  }
  .sidebar-group__arrows {
    float: right;
    .arrow-left {
      display: inline-block;
      cursor: pointer;
      margin-right: $small-spacing;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      &:active,
      &:focus {
        .svg, .svg .styleme {
            fill: $brand-color-main;
            fill-opacity: 1;
        }
      }
      .svg {
        width: 24px;
        height: 24px;
        transition: fill 0.3s ease-in-out;
        .styleme {
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .arrow-right {
      display: inline-block;
      transition: transform 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
      &:active,
      &:focus {
        .svg, .svg .styleme {
            fill: $brand-color-main;
            fill-opacity: 1;
        }
      }
      .svg {
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
        .styleme {
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  @media (max-width: $phone-max) {
    .layout_widget img {
      height: auto;
    }
  }
}

.slick-vertical .sidebar-group__arrows .arrow-left .svg {
  transform: rotate(90deg);
  &:hover {
    transform: rotate(90deg);
  }
}

.slick-vertical .sidebar-group__arrows .arrow-right .svg {
  transform: rotate(270deg);
  &:hover {
    transform: rotate(270deg);
  }
}
