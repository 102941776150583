@import "_variables";
@import "_mixins";

$premium-font: "Roboto condensed";
$newspaper-height: 180px;

body.detail-page--premium {
  /*
  Adv adjustments to reduce CLS
  */
  @media (min-width: $tablet-min) {
    #div-gpt-ad-skin-desktop-premium,
    #div-gpt-ad-skin-desktop-premium div {
      height: 116px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding: 0 !important;
    }
  }
  @media (max-width: $phone-max) {
    #speed_mob_skin {
      height: 100px;
      display: block;
    }
    #speed_mobile_skin {
      position: absolute;
      z-index: 1;
    }
    #speed_mobile_skin.fissa {
      position: fixed;
      top: 90px;
      z-index: 999999999;
      width: 100%;
      height: auto;
    }
    #speed_mobile_skin.riposiziona {
      position: absolute;
    }
    #speed_mobile_skin.ripristina {
      position: absolute !important;
    }
    #speed_mobile_skin_hide {
      display: none;
    }
  }
  /* end of anti-CLS */

  /*
  position: relative makes the skin overlap the .wrapper
  how could it be?
  .header-wrapper {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }*/
  .mobile-menu {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }
  > .wrapper {
    padding-bottom: 0;
    @media (max-width: $phone-max) {
      padding-left: 16px;
      padding-right: 16px;
    }
    .detail-subject {
      @include container();
      // padding-top: 20px;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 0;
      width: auto;
    }
    .detail-heading {
      padding-top: 20px;
      border-bottom: 0;
      // @include container();
      padding-left: 0;
      padding-right: 0;

      .title {
        font-size: 3em;
        line-height: 1em;
        @media (max-width: $phone-max) {
          font-size: 2.5em;
        }
      }
      .subtitle {
        font-size: 20px;
        line-height: 1.6;
        margin-top: 20px;
        font-family: $premium-font, serif;
        color: $text-color-primary;
      }
      .detail-last-modified {
        font-size: 16px;
        font-family: $premium-font, serif;
        strong {
          font-weight: 700;
        }
      }
    }
  }

  .social-block {
    margin: 20px auto;
  }

  .fullwidth-images + .main-content {
    border-top: 0;
  }

  .main-content {
    position: relative;
    padding-bottom: 40px;
    border-top: 1px solid #ddd;
    padding-top: 40px;
    line-height: 1.5em;
    .card {
      border-bottom: 0;
    }
  }

  .detail-last-modified {
    margin-bottom: 0;
  }

  .detail-text {
    font-family: $font-family-body-qn;
    font-weight: 300;
    font-size: 19px;
    line-height: 1.8em;
    position: relative;
    z-index: 1;
    min-height: unset !important;

    &.detail-text--dropcap > p {
      @include dropCap();
    }

    @media (max-width: $phone-max) {
      hyphens: auto;
    }
  }

  .fullwidth-images {
    @media (max-width: $phone-max) {
      margin-left: -10px;
      margin-right: -10px;
      width: auto;
    }
  }

  /* adjust call to action to subscribe to avoid the skin going under the wrapper*/
  #piano-widget {
    padding-bottom: 10px;
    .button--cta {
      margin-bottom: 0;
    }
  }
}

.detail-text--truncated {
  display: none;
}

body.detail-page--paywall {
  .premium-article-bottom {
    display: none;
  }

  .detail-text {
    overflow: hidden;
    display: none;

    &.detail-text--truncated {
      display: block;
    }

    &:after {
      content: "";
      display: block;
      height: 100px;
      position: absolute;
      bottom: 0;
      width: 100%;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.01) 1%,
        rgba(255, 255, 255, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.01) 1%,
        rgba(255, 255, 255, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.01) 1%,
        rgba(255, 255, 255, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
  }
  .side-articles {
    display: none;
  }
}

.premium-heading-bottom {
  position: relative;
  @include clearfix();
}

.premium-abstract {
  padding-bottom: 10px;
  @media (max-width: $phone-max) {
    padding-right: 0;
  }
}
.premium-article-bottom {
  text-align: initial;
  .layout_widget {
    margin-bottom: 80px;
  }
}

.side-articles {
  @include container();

  // @media (min-width: $tablet-min) {
  //   position: absolute;
  //   top: 20px;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }

  @media (max-width: $phone-max) {
    @include clearfix();
  }

  .side-article {
    width: 160px;
    padding-bottom: 40px;
    border: 1px solid #ddd;
    position: sticky;
    top: 10px;
    font-family: "Roboto Condensed", sans-serif;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    transition: transform 0.1s ease-in-out;

    @media (max-width: $phone-max) {
      max-width: 50%;
    }

    &:hover {
      text-decoration: none;
      z-index: 1;
      transform: scale(1.1);
    }
    .image {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .label {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      color: $brand-color-main;
      @include addBrandColor();
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .title {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      color: $text-color-primary;
    }

    .arrow {
      width: 36px;
      height: 36px;
      border: 1px solid #ddd;
      background-color: white;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: 0;
      transition: transform 0.1s ease-in-out;
      transform: translateY(50%);
    }
  }
  .side-article--prev {
    float: left;
    .arrow {
      left: 20px;
      background-image: url(../img/arrow-left.png);
    }
  }
  .side-article--next {
    float: right;
    .arrow {
      right: 20px;
      background-image: url(../img/arrow-right.png);
    }
  }
}
