@import "_variables.scss";
@import "_mixins.scss";

/* to removed later START */
.premium-related-articles {
  width: 660px;
  margin: auto;
  margin-top: 60px;
  max-width: 100%;

  .premium-related-articles__title {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    &:after {
      content: "";
      width: 20%;
      border-bottom: 4px solid red;
      display: block;
      margin: 10px auto 20px;
      border-radius: 3px;
    }
  }
  .card {
    border-bottom: 0;
    .card__text {
      @media (max-width: $phone-max) {
        width: 100%;
      }
    }
  }
}
/* to removed later END */

.premium-article-bottom {
  max-width: 660px;
  margin: 60px auto 0;
  .layout_widget {
    .sidebar-group__title-bar {
      text-align: center;
      margin-bottom: 20px;
      &:after {
        content:"";
        display: block;
        margin: auto;
        height: 4px;
        width: 150px;
        @include addBrandBackgroundColor();
      }
      .sidebar-group__title {
        float: none;
        margin-bottom: 10px;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }
}

