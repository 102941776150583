@import "_variables";
@import "_mixins";
.hamburger {
  width: 40px;
  height: 40px;
  padding: $small-spacing;
  position: relative;
  cursor: pointer;
  float: left;
  &.hamburger--open {
    .hamburger__line {
      &.hamburger__line--top {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        margin: 0;
      }
      &.hamburger__line--mid {
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &.hamburger__line--bottom {
        opacity: 0;
      }
    }
    &+.network-text {
        font-size: 0;
        height: 40px;
      &:after {
        content: "chiudi";
        display: inline-block;
        font-size: 20px;
      }
    }
  }
  .hamburger__line {
    height: 2px;
    width: 100%;
    background-color: $dark-color-primary;
    min-height: 1px;
    width: 20px;
    transition: all 0.3s ease-in-out;
    &.hamburger__line--top {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.hamburger__line--mid {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.hamburger__line--bottom {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }
}
