@import "_variables";
@import "_mixins";

.wrapper {
    @include container(white);
    margin: auto;
    position: relative;

    padding-bottom: 100px;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.main-content {
    position: relative;
    padding-top: $small-spacing;

    @media (min-width: $tablet-min) {
        display: flex;
    }
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.main-column {
    float: left;
    @media (min-width: $tablet-min) {
        margin-right: $default-spacing;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .adv-slot-container {
        max-width: 100%;
        clear: both;
        iframe {
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}

