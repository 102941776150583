@import "_variables.scss";
@import "_mixins.scss";
.status-message {
  padding: 150px 0;
  text-align: center;
  .status-message__code {
    font-size: 144px;
    font-weight: 200;
  }
  .status-message__title {
    font-size: 48px;
    text-transform: uppercase;
  }
  .status-message__description {
    max-width: 600px;
    font-size: 22px;
    margin: auto;
    font-weight: 300;
    margin-bottom: 40px;
  }
  .status-message__button {

  }
}
