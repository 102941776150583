@import "_variables";
@import "_mixins";
.desktop-menu .submenu {
    position: relative;
    background-color: #f1f1f1;
    border-bottom: 1px solid #eaeaea;
    user-select: none;
    @include hideMobile();
    .submenu__inner {
        @include clearfix();
        @include container();
        padding-right: 0;
    }
    .menu-section {}
    .menu-item {
        &.active {
            a {
              @include addBrandColor;
            }
          }
        margin-right: $small-spacing;
        &:last-child {
            margin-right: 0;
        }
    }
    .menu-item__link {
        font-size: 14px;
        color: $dark-color-secondary;
        line-height: 2.2em;
        text-transform: none;
        &:hover {
            color: $dark-color-primary;
        }
    }
}
