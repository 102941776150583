@import '_variables';
@import '_mixins';

.mega-menu {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: none;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border-top: 1px solid $light-color-secondary;
  border-bottom: 3px solid $brand-color-main;
  @include hideMobile();
  .mega-menu__wrapper {
    @include container();
    margin: 40px auto 40px;
  }
}

.mega-menu.mega-menu--visible {
  opacity: 1;
  display: block;
  @include hideMobile();
}

.mega-menu__wrapper {
  @include clearfix();
  .mega-menu__column-title {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: $text-color-primary;
    margin-bottom: 20px;
  }

  .mega-menu__left-column {
    float: left;
    width: 50%;
    .mega-menu__list {
      height: 350px;
      border-right: 1px solid $light-color-secondary;
      margin-right: 20px;
    }
  }
  .mega-menu__right-column {
    float: left;
    width: 50%;
    .mega-menu__list {
      height: 140px;
    }
  }
  .mega-menu__column {
    border-left: 1px solid #eee;
    margin-bottom: 20px;
    border-bottom: 1px solid $light-color-secondary;
    &:last-of-type {
      border-bottom: 0;
    }

    &:first-child {
      border-left: 0;
    }
    &.mega-menu__column--1 {
      float: left;
      width: 33%;
      width: calc(33% + 20px);
      padding-left: 20px;
    }
    &.mega-menu__column--2 {
      float: left;
      width: 66%;
      width: calc(66% - 20px);
    }
    &.mega-menu__column--3 {
      float: left;
      width: 100%;
      border-left: 0;
    }
    &.mega-menu__column--small {
      li a {
        font-size: 14px;
      }
    }
  }

  .mega-menu__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    li {
      a {
        font-size: 16px;
        color: $text-color-secondary;
        &:hover {
          color: $text-color-primary;
          text-decoration: none;
        }
      }
    }
  }
  ul {
    @include clearUl();
  }
  li {
    @include clearLi();
    display: inline-block;
  }
  a {
    color: $text-color-primary;
  }
}

ul.mega-menu__editions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #eee;
  margin-bottom: 45px;
  padding-bottom: 35px;
  row-gap: 32px;

  li {
    text-align: center;
    a {
      display: block;
      // smaller logos on second line
      .svg {
        height: 18px;
      }
      &.iltelegrafo .svg,
      &.economiaelavoro .svg,
      &.salus .svg {
        height: 14px;
      }
      &.luce .svg {
        height: 32px;
      }
    }
  }
}
