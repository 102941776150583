@import "_variables";
@import "_mixins";

.premium-divider {
  background-color: $brand-color-transparent;
  @include clearfix();
  background-image: url("../img/premium-pattern-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $phone-max) {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.premium-divider__container {
  padding: 40px 0;
  max-width: 600px;
  margin: auto;
  position: relative;
  @media (max-width: $phone-max) {
    padding: 20px 20px 0;
  }
}
.premium-divider__title {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  color: $brand-color-main;
  font-weight: 700;
  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: $brand-color-main;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.premium-divider__description {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  max-width: 400px;
  max-width: calc(100% - 140px);
  @media (max-width: $phone-max) {
    padding-bottom: 10px;
  }
}
.premium-divider__image {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.2);
  @media (max-width: $phone-max) {
    right: 10px;
  }
}

.paywall-block-image {
  position: absolute;
  bottom: 0;
  z-index: 2222;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}

.paywall-block {
  z-index: 9999999999;
  margin-bottom: 100px;
  box-shadow: 0px -20px 100px 50px #fff;
  padding: 20px 0 20px 0;
  background-color: #fff;
  display: none;

  .paywall-modal {
    text-align: center;
    img {
      max-width: 100%;
      display: inline-block;
    }
  }
  .paywall-block__background {
  }
}
