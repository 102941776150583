@import "_variables";
@import "_mixins";

.subtitle {
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;

  @media (max-width: $phone-max) {
    font-size: 18px;
    line-height: 1.4em;
  }
}

.title + .subtitle {
  margin-top: 15px;
}
