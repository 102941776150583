@import "_variables";
@import "_mixins";

$blur-size: 20px;
$bg-height: 400px;
$thumbs-width: 200px;
$container-width: 600px;
$image-url: "https://immagini.quotidiano.net/?url=http%3A%2F%2Fp1014p.quotidiano.net%3A80%2Fpolopoly_fs%2F1.4363913.1545907123%21%2FhttpImage%2Fimage.jpg_gen%2Fderivatives%2Fwidescreen%2Fimage.jpg&w=660&h=368&fmt=webp";
$left-arrow-url: "data:image/svg+xml,%3Csvg width='5' height='10' viewBox='0 0 5 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Earrow%3C/title%3E%3Cpath d='M4.867 5.033L.784.95a.458.458 0 1 0-.65.648l3.76 3.758-3.76 3.759a.458.458 0 1 0 .65.648l4.084-4.082a.463.463 0 0 0 0-.648z' fill='%23000000' fill-rule='nonzero'%3E%3C/path%3E%3C/svg%3E";
$right-arrow-url: "data:image/svg+xml,%3Csvg width='5' height='10' viewBox='0 0 5 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Earrow%3C/title%3E%3Cpath d='M4.867 5.033L.784.95a.458.458 0 1 0-.65.648l3.76 3.758-3.76 3.759a.458.458 0 1 0 .65.648l4.084-4.082a.463.463 0 0 0 0-.648z' fill='%23fff' fill-rule='nonzero'%3E%3C/path%3E%3C/svg%3E";

/* carosuel */
.fullwidth-images {
  width: 100%;
}

.fullwidth-image-component {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  overflow: hidden;
}

.fullwidth-image__slide {
}

.fullwidth-image__container {
  height: $bg-height;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (max-width: $phone-max){
    height: auto;
    max-height: $bg-height;
  }
}

/* big image */

.fullwidth-image {
  position: relative;
  z-index: 1;
  max-width: $container-width;
  margin: auto;
  width: 100%;
  text-align: center;
  height: 100%;
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 400px;
    height: 100%;
    img {
      display: inline-block;
      max-width: 100%;
      max-height: 400px;
      width: auto;
      height: auto;
    }
  }
}

.fullwidth-image__background {
  background: url($image-url);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -($blur-size);
  left: -($blur-size);
  right: -($blur-size);
  bottom: -($blur-size);
  background-position: center;
  filter: blur($blur-size) saturate(0.5);
}

.fullwidth-image__background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.4;
}

/* image caption */

.image-caption {
  position: relative;
  width: $container-width;
  margin: 10px auto;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  color: $text-color-secondary;
  @media (max-width: 768px) {
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    padding: 10px;
  }
  .slick-slider & {
    color: white;
  }
  .slick-slider .slick-current & {
    transition: color 1s ease-in-out;
    color: $text-color-secondary;
  }
}

/* thumbnails */

.thumbs-container {
  position: absolute;
  top: $bg-height;
  left: 50%;
  z-index: 2;
  width: $container-width;
  max-width: 100%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    transform: translate(-50%, -100%);
    width: auto;
    padding-bottom: 10px;
  }
}

.thumbs {
  width: $thumbs-width;
  float: right;
  .slick-current {
    .thumb {
      border: 2px solid white;
    }
  }
  .thumb {
    display: inline-block;
    width: 60px;
    max-width: 60px;
    height: 60px;
    vertical-align: middle;
    &.active {
      padding: 1px;
      img {
        border: 1px solid white;
      }
    }
    img {
      max-width: 100%;
    }
  }
}

.thumbs-navigation {
  position: absolute;
  top: 0;
  right: -30px;
  z-index: 3;
  text-align: center;
  > button {
    display: block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border: 0;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .previous {
    background-color: white;
    margin-bottom: 10px;
    transform: rotate(180deg);
    background-image: url($left-arrow-url);
  }
  .next {
    background-image: url($right-arrow-url);
    background-color: black;
    color: white;
  }
}
