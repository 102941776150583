@keyframes pulse {
    10% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

@keyframes animateBackgroundSize {
    0% {

        background-size: 0% 100%;
    }

    100% {
        background-size: 100% 100%;
    }
}
