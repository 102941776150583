@import "_variables.scss";
@import "_mixins.scss";

.pending {
  max-width: 100%;
  width: 550px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 30px 60px 50px;
  margin: 0 auto 80px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.02), 0 0 5px 0 rgba(0, 0, 0, 0.1),
    0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  @media (max-width: $phone-max) {
    padding: 20px 20px 40px;
    margin-bottom: 40px;
  }

  &.email--sent {
    .pending__email {
      a {
        display: none;
      }
    }
  }
  .pending__image {
    background-image: url("../img/svg/email-icon.svg");
    height: 28px;
    width: 36px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 20px;
    background-size: 100%;
  }

  .pending__title {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .pending__email {
    background-color: #eee;
    padding: 10px 130px 10px 20px;
    border: 1px solid #ddd;
    border-radius: 40px;
    margin-bottom: 20px;
    position: relative;
    text-align: left;
    span {
      color: #575757;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      @media (max-width: $phone-max) {
        font-size: 14px;
      }
    }
    a {
      background-color: white;
      padding: 4px 14px;
      border-radius: 30px;
      border: 1px solid #ccc;
      float: right;
      position: absolute;
      top: 5px;
      right: 5px;
      transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: white;
        @include addBrandBackgroundColor();
      }
    }
  }

  .pending__description {
    margin-bottom: 40px;
    font-size: 16px;
    a {
      font-weight: 700;
    }
  }

  .pending__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: #eee;
    font-size: 14px;
    color: #575757;
  }
}
