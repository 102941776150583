.detail-page {
  .detail.detail--video {
    .subtitle {
      font-size: 16px;
      line-height: 1.6;
    }
    .detail-body {
      >iframe {
        max-width: 100%;
      }
    }
  }
}
