// Brand colors
// main branding
$brand-color-main: #005AC8;
$brand-color-dark: darken($brand-color-main, 10%);
$brand-color-transparent: transparentize($brand-color-main, .9);
$brand-color-pubbliredazionali: #417505;
$brand-color-green: #1B8841;
// il resto brand colors
//$ilresto-color-main: #1e8acc;
$ilresto-color-main: #005AC8;
$ilresto-color-dark: darken($ilresto-color-main, 10%);
$ilresto-color-transparent: transparentize($ilresto-color-main, .9);
$ilresto-color-pubbliredazionali: #417505;
// telegrafo brand colors
$iltelegrafo-color-main: #9d1a33;
$iltelegrafo-color-dark: darken( $iltelegrafo-color-main, 10%);
$iltelegrafo-color-transparent: transparentize( $iltelegrafo-color-main, .9);
$iltelegrafo-color-pubbliredazionali: #417505;
// font families
$font-family-body-qn: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
// colors
$dark-color-primary: black;
$dark-color-secondary: #575757;
$light-color-primary: #ddd;
$light-color-secondary: #eee;
// text colors
$title-color: $dark-color-primary;
$abstract-color: $dark-color-primary;
$text-color-primary: $dark-color-primary;
$text-color-secondary: $dark-color-secondary;
$text-color-muted: #999;
//social colors
$facebook-color: #3b5999;
$twitter-color: #55acee;
$instagram-color: #e4405f;
$mail-color: #575757;
$whatsapp-color: #00E676;
// layout
$container-size: 1024px;
// spacing
$default-spacing: 20px;
$small-spacing: 10px;
$large-spacing: 40px;
// media queries
$small-phone-min:0px;
$small-phone-max:319px;
$phone-min: 320px;
$phone-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$desktop-min: 1024px;
$desktop-max: 1199px;
$large-desktop-min: 1200px;
