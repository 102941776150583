@import "_variables";
@import "_mixins";

.horoscope {
  @include clearfix();
  font-family: AppleSDGothicNeo-Regular, "lucida grande", tahoma, verdana, arial,
    sans-serif, "Segoe UI Symbol", NotoColorEmoji,
    EmojiSymbols, Symbola, Noto, "Android Emoji", AndroidEmoji,
    "Arial Unicode MS", "Zapf Dingbats", AppleColorEmoji, "Apple Color Emoji";
  .horoscope__sign {
    float: left;
    width: 33%;
    height: 100px;
    text-align: center;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
    &:hover {
        text-decoration: none;
        background-color: #f4f4f4;
        border-top-color: #ccc;
        .horoscope__symbol {
        }
        .horoscope__name {
        }
    }
  }
  .horoscope__symbol {
    font-size: 36px;
    color: $brand-color-main;
    @include addBrandColor();
    line-height: normal;
    font-weight: bold;
    transition: color .3s ease-in-out;
  }
  .horoscope__name {
    line-height: normal;
    color: $text-color-primary;
    transition: color .3s ease-in-out;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 14px;
  }
}
