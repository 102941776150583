@import "_variables";
@import "_mixins";
$card-margin: $small-spacing;
$very-large-image-height: 371px;
$large-image-height: 280px;
$small-image-height: 182px;
$sidebar-image-height: 120px;

.card {
  max-width: 100%;
  margin-bottom: $card-margin;
  border-bottom: 1px solid $light-color-primary;
  transition: all 0.3s ease-in-out;
  @media (max-width: $phone-max) {
    background-color: white;
  }
  &:last-child {
    .main-column & {
      border-bottom: 0;
    }
  }
  @include clearfix();
  img {
    max-width: 100%;
    height: auto;
    transition: transform 0.7s ease-in-out;
  }
}

.apertura .card:last-child {
  border-bottom: 1px solid $light-color-primary;
}

/* card modifiers: image */

.card__image-placeholder {
  position: relative;
  padding-top: 40%;
  background: #f0f0f0;

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

.card--image-very-large {
  border-color: transparent;
  .card__image {
    max-height: 400px;
    @media (min-width: $tablet-min) {
      min-height: $very-large-image-height;
    }

    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .abstract {
    line-height: 1.4em;
    margin-bottom: $small-spacing;
  }
  .signature {
    margin-bottom: 10px;
  }
}

.card--image-large {
  .abstract {
    margin-bottom: $card-margin;
  }
  .signature {
    margin-bottom: 10px;
  }
  .card__image {
    @media (min-width: $tablet-min) {
      min-height: $large-image-height;
    }
    @media (max-width: $phone-max) {
      width: 100%;
    }
    a {
      display: inline-block;
      position: relative;
      z-index: 1;
      display: flex;
      width: 100%;
    }
    picture {
      display: inline-block;
      width: 100%;
      img {
        margin: auto;
      }
    }
  }
}
.card--has-background {
  .card__image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: black;
    @media (max-width: $phone-max) {
      width: 100%;
      img {
        @media (max-width: $phone-max) {
          max-height: 180px;
        }
      }
    }
    img {
      display: block;
      position: relative;
      z-index: 1;
    }
    .image__background {
      opacity: 0.4;
    }
  }
  .card--image-small {
    display: flex;
    flex-wrap: wrap;
    .card__image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @media (min-width: $tablet-min) {
        float: left;
        width: 30%;
        width: 330px;
        overflow: hidden;
      }
      @media (max-width: $phone-max) {
        width: 100%;
      }
    }
  }
  .card__image a .image {
    width: auto;
    margin: auto;
    max-width: 100%;
  }
}

.card--image-no-fixed-width {
  .card--image-small {
    .card__image {
      width: auto;
      max-width: 330px;
    }
  }
  .card--image-large {
    .card__image {
      max-height: none;
      a .image {
        width: 660px;
      }
    }
  }
}

.image__background {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  filter: blur(10px) saturate(0.5);
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card--image-small {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .card__image {
    @media (min-width: $tablet-min) {
      float: left;
      width: 30%;
      width: 330px;
      overflow: hidden;
      min-height: $small-image-height;

      .colsx-layout_widget & {
        width: 295px;
      }
    }
    @media (max-width: $phone-max) {
      width: 100%;
    }
    a {
      display: inline-block;
      z-index: 1;
      display: flex;
      width: 100%;
    }
    picture {
      display: inline-block;
      width: 100%;
    }
    .image {
      width: 100%;
    }
    .card__image__background {
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      filter: blur(10px) grayscale(0.5);
      z-index: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .card__text {
    @media (min-width: $tablet-min) {
      float: left;
      width: 70%;
      width: 330px;
    }
    @media (max-width: $phone-max) {
      position: relative;
      .card__subject.card__subject--top {
        position: absolute;
        top: -30px;
        left: 0;
        height: 30px;
        line-height: 30px;
        border-radius: 0 5px 0 0;
        z-index: 2;
      }
    }
  }
  .card__gallery {
    float: left;
    width: 30%;
    .image__container {
      height: 100px;
    }
  }
  .card__subject--top {
    padding-top: 0;
  }
  .card__text + .card__footer {
    padding-top: 1px; //this is necessary to push against the floated "card__text" element
  }
  .card__footer {
    clear: none;
    float: left;
    &.card__footer--date {
      float: none;
      clear: both;
    }
    &.card__footer--related {
      clear: both;
      float: none;
    }
    &.card__footer--author {
      width: auto;
      float: left;
      .signature {
        width: auto;
      }
    }
    .date {
      @media (min-width: $phone-max) {
        position: absolute;
        top: 0;
        right: 0;
        color: $text-color-secondary;
      }
    }
  }
}

.card--no-image.card--text-very-large {
  @media (min-width: $tablet-min) {
    padding-left: 0;
  }
  .card__footer {
    .date {
      position: static;
      float: right;
    }
  }
}

.card--no-image {
  position: relative;
  padding-left: $small-spacing;
  @media (max-width: $phone-max) {
    padding-left: $small-spacing;
    padding-right: $small-spacing;
  }
  .card__text {
    float: none;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .card__subject {
    padding: 0 0 5px 0;
  }
  .card__title,
  .card__abstract,
  .card__footer {
    margin-left: 0;
    margin-right: 0;
  }
  .signature {
    margin-bottom: 10px;
  }
  .card__footer {
    .date {
      @media (min-width: $phone-max) {
        position: absolute;
        top: 0;
        right: 0;
        color: $text-color-secondary;
      }
    }
  }
}

/* card modifiers: text */

.card--text-very-large {
  .title {
    font-size: 44px;
    line-height: 52px;
    @media (max-width: $phone-max) {
      font-size: 30px;
      line-height: 1.2em;
    }
    a {
      line-height: 1;
      @media (max-width: $phone-max) {
        line-height: 1.2em;
      }
    }
  }
  .card__abstract {
    font-size: 20px;
    @media (max-width: $phone-max) {
      font-size: 16px;
      line-height: normal;
    }
  }
}

.card--text-large {
  .title {
    line-height: 1.3em;
    @media (max-width: $phone-max) {
      font-size: 22px;
    }
  }
  .card__abstract {
    font-size: 18px;
    @media (max-width: $phone-max) {
      font-size: 16px;
      line-height: normal;
    }
  }
}

.card--text-small {
  .title {
    font-size: 24px;
    line-height: normal;
    @media (max-width: $phone-max) {
      font-size: 22px;
    }
  }
}

/* card modifiers: vari */

.card.card--premium {
  .card__subject {
    .subject {
      &:before {
        content: "";
        width: 22px;
        height: 22px;
        background-image: url(../img/paywall-blue.svg);
        display: block;
        border-radius: 50%;
        background-size: 100%;
        position: absolute;
        left: 5px;
        top: 6px;
        body.qn & {
          background-image: url(../img/paywall.svg);
        }
        body.iltelegrafo & {
          background-image: url(../img/paywall-telegrafo.svg);
        }
      }
      padding: 5px 10px 0 25px;
    }

    &.card__subject--top {
      @media (min-width: $tablet-min) {
        position: relative;
      }

      .subject {
        padding-left: 30px;

        &::before {
          top: 0;
          left: 0;

          @media (max-width: $phone-max){
            top: 4px;
            left: 10px;
          }
        }
      }
    }
  }
}

// no-image fix top for prod bug
.card.card--premium.card--no-image .card__subject.card__subject--top .subject:before {
  top: 2px;
}

.card--pubbliredazionale {
  .card__subject {
    .subject {
      color: $brand-color-pubbliredazionali;
    }
  }
  .card__title {
    .related {
      color: $brand-color-pubbliredazionali;
    }
  }
}

.card--numbered {
  .card__image {
    &:after {
      counter-increment: section;
      content: counter(section);
      display: block;
      position: absolute;
      bottom: -12px;
      color: white;
      font-size: 48px;
      font-weight: 700;
      right: $card-margin;
      text-shadow: 1px 1px 50px black;
      line-height: normal;
      z-index: 99;
    }
  }
}

.card--border {
  border: 1px solid $light-color-primary;
  border-radius: 3px;
  box-shadow: 0 0 10px #ccc;
  padding: $default-spacing;
  background-color: white;
}

.card--round-thumbnail {
  .card__image {
    float: left;
    margin-right: $card-margin;
    margin-top: $small-spacing;
    margin-bottom: $card-margin;
    position: relative;
    @media (max-width: $phone-max) {
      width: 30%;
      float: left;
      margin-right: 0;
    }
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $light-color-primary;
      margin: auto;
      filter: saturate(0.5);
    }
    & + .card__text {
      float: left;
      width: 570px;
      margin-bottom: 0;
      @media (max-width: $phone-max) {
        width: 70%;
      }
    }
  }
}

.card--square-thumbnail {
  display: flex;
  flex-wrap: wrap;
  .card__image {
    float: left;
    margin-right: $card-margin;
    position: relative;
    @media (max-width: $phone-max) {
      width: 30%;
      width: calc(120px);
      float: left;
      margin-right: 0;
    }
    img {
      width: 120px;
      height: 120px;
      overflow: hidden;
      margin: auto;
      filter: saturate(0.5);
    }
    & + .card__text {
      float: left;
      width: 530px;
      margin-bottom: 0;
      @media (max-width: $phone-max) {
        width: 70%;
        width: calc(100% - 120px);
      }
    }
  }
}

.card--sidebar {
  border-bottom: 0;
  .card__image {
    min-height: $sidebar-image-height;
  }
  .card__title {
    margin: 5px 0 0;
  }
  .title {
    font-size: 20px;
    line-height: normal;
  }
}

@media (min-width: $desktop-min) {
  .card--halfwidth {
    max-width: 50%;
    float: left;
    padding-left: 2px;
    padding-right: 2px;
    border-bottom: 0;
  }

  .card--halfwidth + .card--halfwidth {
    padding-left: 10px;
  }

  .card:not(.card--halfwidth) {
    clear: both;
  }
}

/* image */

.card__image {
  min-height: 30px;
  position: relative;
  display: block;
  max-height: 371px;
  overflow: hidden;
  user-select: none;
}

/* card subject */

.card__subject {
  background-color: white;
  padding: 5px 10px 0;
  .subject {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
  }
}

.card__subject--bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 5px 0 0;
  z-index: 2;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.2);
}

.card__subject--top {
  margin-bottom: -($card-margin);
  padding-bottom: 0;
  padding-left: 0;
  @media (max-width: $phone-max) {
    padding-left: inherit;
  }

  .card--sidebar & {
    padding-top: 0;
    padding-bottom: 5px;
  }
}

.card__text {
  padding: 0 $card-margin;
}

/* card title */

.card__title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  margin: $card-margin 0;
  .title {
    display: inline-block;
  }
}

/* card abstract */

.card__abstract {
  font-family: "Roboto Condensed", sans-serif; //font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: $text-color-primary;
  margin-top: $card-margin;
}

/* card footer */

.card__footer {
  margin-left: $card-margin;
  margin-right: $card-margin;
  font-size: 13px;
  clear: both;
  @include clearfix();
  @media (max-width: $phone-max) {
    margin-bottom: 10px;
  }
  &.card__footer--date {
    .date {
      float: none;
      width: auto;
      text-align: left;

      @media (min-width: $tablet-min) {
        .card--halfwidth & {
          margin-right: 10px;
        }
      }
    }
  }
  &.card__footer--author {
    float: none;
    width: auto;
    text-align: left;
  }
  &.card__footer--date.card__footer--author {
    .date {
      width: 30%;
      float: right;
      text-align: right;
      width: 30%;
      margin-bottom: 10px;
      font-weight: 300;
    }
  }
}

.signature {
  @include clearfix();
}

.signature__prefix {
  margin-right: 5px;
}

.signature__author {
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
}

.signature__external {
  margin-top: $small-spacing;
}

.signature__external-logo {
  display: inline-block;
  vertical-align: middle;
  @include addBrandBackgroundColor();
  margin-right: $small-spacing;
  padding: 5px;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  .svg {
    width: 16px;
    height: 16px;
    max-width: 100%;
    max-height: 100%;
    .styleme {
      fill: white;
    }
  }
}

.signature__external-name {
  display: inline-block;
  vertical-align: middle;
}

.date {
  float: right;
  text-align: right;
  width: 30%;
  margin-bottom: $card-margin;
  font-weight: 300;
}
