@import "_variables.scss";
@import "_mixins.scss";
.pagination-block {
  margin-top: 20px;
  .pagination__button {
    background-color: white;
    padding: 10px 20px;
    border: 1px solid $light-color-primary;
    border-radius: 3px;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
    &:hover {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
    &:active,
    &:focus {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
    @media (max-width: $phone-max) {
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
    }
    @include clearfix();
    &.pagination__button--prev {
      float: left;
      .pagination__text {
        margin-left: 10px;
      }
    }
    &.pagination__button--next {
      float: right;
      .pagination__text {
        margin-right: 10px;
      }
    }
  }
  .pagination__text {
    font-size: 18px;
    text-transform: uppercase;
    color: $text-color-primary;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    @media (max-width: $phone-max) {
      font-size: 14px;
    }
  }
  .pagination__arrow {
    color: $text-color-primary;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
  }
}
