@import "_variables";
@import "_mixins";

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    &.overlay--video {
        background-color: rgba(0, 0, 0, 0.4);
    }
    .overlay__text {
        color: white;
        text-align: center;
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &:hover {
        .overlay__icon {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

.overlay__number {
    font-size: 24px;
    font-weight: 700;
    &:hover {
        color: white;
    }
}

.overlay__see-more {
    line-height: normal;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
        color: white;
    }
}

.overlay__icon {
    width: 24px;
    height: 24px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: translate(-50%, -50%) scale(1.1);
    }
}
