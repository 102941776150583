@import "_variables.scss";
@import "_mixins.scss";

li.menu-item.menu-item--login {
  .login-button {
    display: block;
    line-height: 1;
    padding: 2px 8px 2px 0;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.3s ease-in-out;
    color: black;
    background-color: white;
    position: relative;
    line-height: 40px;
    padding-right: 40px;
    svg,
    .svg {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 5px;
      right: 0;
      .styleme circle {
        @include addBrandFillColor();
      }
    }
    &:hover {
      @include addBrandColor();
    }
    &:after {
      content: "1";
      height: 16px;
      width: 16px;
      background-color: $brand-color-main;
      @include addBrandBackgroundColor();
      display: block;
      position: absolute;
      top: 0;
      color: white;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      border: 1px solid white;
      right: -10px;
      border-radius: 50%;
      animation: pulse 2s ease-in-out infinite;
    }
  }
}

.user-profile {
  float: right;
  height: 40px;
  align-items: center;
  display: flex;

  .user-info {
    display: inline-block;
    margin-right: 10px;
    text-align: right;
    vertical-align: middle;
    .user-info__name {
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
    }
  }
  .user-picture {
    border-radius: 50%;
    overflow: hidden;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border: 2px solid white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    transition: border-color 0.3s ease-in-out;
    img {
      vertical-align: middle;
      display: inline-block;
      max-width: 100%;
    }
  }
  .user-panel {
    display: none;
    position: absolute;
    top: 40px;
    left: -20px;
    right: -20px;
    border: 1px solid #ddd;
    border-top: 1px solid white;
    background-color: white;
    box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
    @media (max-width: $phone-max) {
      right: -10px;
      left: unset;
      z-index: 999;
    }
    .user-panel__link {
      text-transform: uppercase;
      line-height: 1;
      display: block;
      font-size: 14px;
      @include addBrandColor();
      font-weight: 700;
      padding: 15px 20px;
      border-top: 1px solid #ddd;
    }
  }
  &:hover {
    .user-panel {
      display: block;
    }
    .user-picture {
      @include addBrandBorderColor();
    }
  }
}
