@import "_variables.scss";

$title-height: 25px;

.section-title-container {
  padding-top: $small-spacing;
  @media (max-width: $phone-max) {
    padding: $small-spacing;
  }
}

.section-title {
  float: right;
  @media(max-width: $phone-max){
    position: absolute;
    top: -200px;
  }
  .title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #575757;
    height: $title-height;
    line-height: $title-height;
    @media(max-width: $phone-max){
      font-size: 16px;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 300;
  }
}

.section-image {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  @media (max-width: $phone-max){
    display: none;
  }
  img {
    max-width: 100%;
    margin: auto;
    @media (min-width: $tablet-min) {
      margin-top: $small-spacing;
      margin-bottom: $small-spacing;
    }
  }
}
