@import "_variables";
@import "_mixins";
.abstract {
  padding-bottom: 2px;
  margin-bottom: $small-spacing;
  line-height: 1.6em;
  p {
    display: inline-block;
    line-height: 1.6em;
    margin-bottom: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.abstract__related {
  .related {
    margin-left: $small-spacing;
    font-weight: 700;
  }
}
