@import "_variables.scss";
@import "_mixins.scss";

#piano-widget {
  margin: auto;
  max-width: 640px;
}

#piano-widget-fixed {
  position: fixed;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 80px rgba(0,0,0, 0.2);
  z-index: 10000000000;
}

#piano-widget-bottom-left {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 400px;
  z-index: 10000000000;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(0,0,0,.3);
  @media(max-width: $phone-max){
    max-width: 80%;
  }
}

body.tp-modal-open {
  @media(max-width: $phone-max) {
    position: relative !important;
    height: auto !important;
    overflow: visible !important;
  }
}